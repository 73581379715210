
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {LogService} from '../loggingService/log.service';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {
	}

	handleError(error: Error) {
		const loggingService = this.injector.get(LogService);
		const location = this.injector.get(LocationStrategy);
		const message = error.message ? error.message : error.toString();
		const url = location instanceof PathLocationStrategy ? location.path() : '';

		loggingService.error(message + ', ' + url + ', ' + location);

		throw error;
	}
}

