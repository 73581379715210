import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EntryDB} from "../../../_entities/entryDB";

@Component({
	selector: 'app-entry',
	templateUrl: './entry.component.html',
	styleUrls: ['./entry.component.scss']
})

/**
 * Angular component represents the structure of an entry.
 */
export class EntryComponent {
	@Input() entry: EntryDB
	@Input() areAbsentEmployeesSelected: boolean
	@Input() isEntrySelected: boolean;
	@Output() entrySelected: EventEmitter<EntryDB> = new EventEmitter<EntryDB>();

	constructor() {
		this.isEntrySelected = false;
	}

	/**
	 * Selects the entry for editing.
	 */
	selectEntry() {
		this.entrySelected.emit(this.entry)
		this.isEntrySelected = true;
	}
}
