<div [ngClass]="isEntrySelected ? 'entry-selected' : 'entry' "
	 (click)="selectEntry()">
	<!-- which fields will be showed depends on absent or present employees in filter settings are selected -->
	<p class="entry-name">{{entry.user_name}}</p>
	<p *ngIf="!areAbsentEmployeesSelected" class="entry-workplace">{{entry.place}}</p>
	<p *ngIf="!areAbsentEmployeesSelected" class="entry-customer">{{entry.customers}}</p>
	<p *ngIf="areAbsentEmployeesSelected" class="entry-reason">{{entry.reason}}</p>
	<p *ngIf="!areAbsentEmployeesSelected" class="entry-worktime">{{entry.time_from}} - {{entry.time_until}}</p>
	<p class="entry-notice">{{entry.note}}</p>
</div>
