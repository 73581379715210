<div class="shiftplan-container">
	<app-alerts></app-alerts>

	<div class="calendar-card">
		<mat-card>
			<mat-card-header>
				<div mat-card-avatar>
					<mat-icon class="material-icons md-30">date_range</mat-icon>
				</div>
				<mat-card-title>
					<ng-container>Einsatzplan Office </ng-container>
					<mat-form-field appearance="outline" floatLabel="always">
						<input [matAutocomplete]="auto"
							   matInput
							   (input)="filterUsers()"
							   [formControl]="userControl"
							   placeholder="Wähle einen Mitarbeiter"
							   required>
						<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
										  (optionSelected)="onUserSelect($event)">
							<mat-option *ngFor="let user of filteredUsers" [value]="user">
								{{user.name}}
							</mat-option>
						</mat-autocomplete>
					</mat-form-field>
				</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<mat-card-content>
				<app-shiftplan *ngIf="user" [userId]="user.user_id"></app-shiftplan>
			</mat-card-content>
		</mat-card>
	</div>
</div>
