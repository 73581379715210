<div class="ticket-container">


	<div class="ticket-item">
		<app-alerts></app-alerts>
		<mat-card class="mat-card-single">
			<mat-card-header>
				<div mat-card-avatar>
					<mat-icon class="material-icons md-30">announcement</mat-icon>
				</div>
				<mat-card-title
				>Berechtigungen & Probleme
				</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<mat-card-content class="card-content-ticket">
						<form [formGroup]="ticketForm">
							<mat-form-field>
								<mat-label>Um was geht es ?</mat-label>
								<mat-select formControlName="selection" [(ngModel)]="select">
									<mat-option *ngFor="let problem of problems" [value]="problem">{{problem}}</mat-option>
								</mat-select>
							</mat-form-field>
							<br/>
							<br/>
							<mat-form-field>
								<mat-label>Bitte gib eine kurze Beschreibung an...</mat-label>
								<textarea
									matInput
									formControlName="description"
									[(ngModel)]="desc"
								></textarea>
							</mat-form-field>
						</form>
			</mat-card-content>
			<mat-card-actions align="end">
				<button mat-flat-button color="accent"
						[disabled]="!desc || !select"
						title="Senden"
						(click)="sendTicket()"
				>
					Senden
				</button>
			</mat-card-actions>
		</mat-card>
	</div>
</div>

