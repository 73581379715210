import { Injectable } from '@angular/core';
import { Alert } from '../../_entities/alerts';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class AlertsService {
	alerts: Alert[] = [];
	private keepAfterRouteChange = false;

	constructor(private router: Router) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (this.keepAfterRouteChange) {
					// only keep for a single route change
					this.keepAfterRouteChange = false;
				} else {
					// clear alert messages
					this.clear();
				}
			}
		});
	}

	add(message: string, debug: string, type: string) {
		this.alerts.push({ message, debug, type });
	}

	remove(alert: Alert) {
		this.alerts.splice(this.alerts.indexOf(alert), 1);
	}

	clear() {
		this.alerts = [];
	}

	success(message: string, debug: string) {
		this.alerts.push({ message, debug, type: 'success' });
	}
	warn(message: string, debug: string) {
		this.alerts.push({ message, debug, type: 'danger' });
	}
}
