<div *ngFor="let alert of alertsService.alerts">
  <ngb-alert
    type="{{ alert.type }}"
    (close)="close(alert)"
    class="fade show shadow"
  >
    <div>{{ alert.message }}</div>
    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <pre
      *ngIf="alert.debug"
      class="alert-pre border bg-light p-2"
    ><code>{{alert.debug}}</code></pre>
  </ngb-alert>
</div>
