import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class CustomerService {

	constructor(
		private http: HttpClient
	) {
	}

	getEntries(): Observable<string[]> {

		return this.http.get<string[]>(`${environment.OkitApiGatewayURL}/customer`)
	}
}
