import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EntryDB} from "../../_entities/entryDB";
import {EntriesOverviewService} from '../../_services/eintriesOverviewService/entries-overview.service';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {reasons, placeToWork, hours, minutes} from '../../_shared/constants';
import {CustomerService} from "../../_services/customerService/customer.service";

@Component({
	selector: 'app-editing-window',
	templateUrl: './editing-window.component.html',
	styleUrls: ['./editing-window.component.scss'],
	encapsulation: ViewEncapsulation.None
})

/**
 * Angular component represents the editing entry window where the entry can be edited or deleted.
 */
export class EditingWindowComponent {
	@Input() entry: EntryDB;
	@Input() isWorkingDay: boolean;
	@Output() closeEditingWindow: EventEmitter<null> = new EventEmitter<null>();

	entryForm = new FormGroup({
		isWorkingDayForm: new FormControl<boolean>(null),
		reasonForm: new FormControl<string>(null, Validators.required),
		customerForm: new FormControl<string[]>(null, Validators.required),
		workplaceForm: new FormControl<string>(null, Validators.required),
		startTimeHoursForm: new FormControl<string>(null),
		startTimeMinutesForm: new FormControl<string>(null),
		endTimeHoursForm: new FormControl<string>(null),
		endTimeMinutesForm: new FormControl<string>(null),
		noteForm: new FormControl<string>(null)
	});
	workplaces: string[] = placeToWork;
	customers: string[];
	reasons: string[] = reasons;
	hours: string[] = hours;
	minutes: string[] = minutes;
	editedEntry: EntryDB;

	constructor(private entriesOverviewService: EntriesOverviewService, private customerService: CustomerService) {
		this.editedEntry = null;
		// get all entries from AWS DynamoDB sheetplan table merged with user table for current date
		this.customerService.getEntries().subscribe(data => {
			this.customers = data
		});
	}

	/**
	 * Turns on editing mode to edit selected entry and sets up form for it.
	 */
	editEntry(): void {
		this.editedEntry = this.entry;
		this.entryForm.patchValue({
			isWorkingDayForm: this.entry['is_working_day'],
			reasonForm: this.entry['is_working_day'] == false ? this.entry['reason'] : null,
			customerForm: this.entry['is_working_day'] == true ? this.entry['customers'].split(',') : null,
			workplaceForm: this.entry['is_working_day'] == true ? this.entry['place'] : null,
			startTimeHoursForm: this.entry['is_working_day'] == true ? this.entry['time_from'].split(':')[0] : null,
			startTimeMinutesForm: this.entry['is_working_day'] == true ? this.entry['time_from'].split(':')[1] : null,
			endTimeHoursForm: this.entry['is_working_day'] == true ? this.entry['time_until'].split(':')[0] : null,
			endTimeMinutesForm: this.entry['is_working_day'] == true ? this.entry['time_until'].split(':')[1] : null,
			noteForm: this.entry['note']
		})
		this.entryForm.get('workplaceForm').valueChanges.pipe(
			startWith(''),
			map(() => this.workplaces)
		);
		this.entryForm.get('customerForm').valueChanges.pipe(
			startWith(''),
			map(() => this.customers)
		);
		this.entryForm.get('reasonForm').valueChanges.pipe(
			startWith(''),
			map(() => this.reasons)
		);
	}

	/**
	 * Turns off entry editing mode.
	 */
	notEditEntry(): void {
		this.editedEntry = null;
	}

	/**
	 * Switches between working day and not working day entry form.
	 * @param e The click event.
	 */
	onCheckBoxClick(e): void {
		this.isWorkingDay = e.target.checked;
	}

	/**
	 * Deletes the selected entry from AWS DynamoDB sheetplan table.
	 */
	deleteEntry(): void {
		this.entriesOverviewService.deleteEntry(this.entry['user_id'], new Date(this.entry['date'])).subscribe(() => {
			this.closeEditingWindow.emit();
		})
	}

	/**
	 * Updates the selected entry in AWS DynamoDB sheetplan table.
	 */
	updateEntry(): void {
		this.entriesOverviewService.updateEntry(this.createEntry()).subscribe(() => {
			this.closeEditingWindow.emit();
		})
	}

	/**
	 * Creates an entry from entry editing form for update function.
	 * @returns An entry object.
	 */
	createEntry(): EntryDB {
		return {
			user_id: this.entry['user_id'],
			date: this.entry['date'],
			is_working_day: this.isWorkingDay,
			customers: String(this.entryForm.value['customerForm']),
			place: this.entryForm.value['workplaceForm'],
			time_from: `${this.entryForm.value['startTimeHoursForm']}:${this.entryForm.value['startTimeMinutesForm']}`,
			time_until: `${this.entryForm.value['endTimeHoursForm']}:${this.entryForm.value['endTimeMinutesForm']}`,
			reason: this.entryForm.value['reasonForm'],
			note: this.entryForm.value['noteForm'] == null ? '' : this.entryForm.value['noteForm']
		};
	}


}
