import {Component} from '@angular/core';
import {AlertsService} from '../../../_services/alertservice/alerts.service';
import {Alert} from '../../../_entities/alerts';

@Component({
	selector: 'app-alerts',
	templateUrl: './alerts.component.html',
	styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent {
	constructor(public alertsService: AlertsService) {
	}


	close(alert: Alert) {
		this.alertsService.remove(alert);
	}

	clear() {
		this.alertsService.clear();
	}
}
