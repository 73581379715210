import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

import {OauthService} from '../oauthService/oauth.service';
import {User} from '../../_entities/user';
import {LogService} from '../loggingService/log.service';
import {GlobalErrorHandler} from '../errorHandler/globalErrorHandler';
import {HttpClient} from '@angular/common/http';
import {GraphUrl} from '../../_shared/constEnvironment';
import {Observable} from 'rxjs';
import {EntryDB} from "../../_entities/entryDB";

@Injectable({
	providedIn: 'root'
})
export class OutlookService {

	constructor(
		private authService: OauthService,
		private logger: LogService,
		private globalErrorHandler: GlobalErrorHandler,
		private http: HttpClient
	) {
		this.authService.user$.subscribe(user => this.user = user);
	}

	user: User = {};

	public writeEntryMail(entryData) {
		let subject: string;
		let mailContent: string = '';
		if (entryData.length > 0) {
			subject = `[Einsatzplan] vom ${entryData[0]['date']} bis ${entryData[entryData.length - 1]['date']}`;
			for(const entry of entryData)
			{
				mailContent += this.getMailContent(entry)
			}
		} else {
			subject = `[Einsatzplan] am ${entryData['date']}`;
			mailContent += this.getMailContent(entryData)
		}
		mailContent += `</br>Viele Grüße</br>${this.user.givenName} ${this.user.surname}`;
		return {
			message: {
				subject,
				body: {
					contentType: 'HTML',
					content: `Hallo OfficeTeam, </br> </br> ${mailContent}`
				},

				toRecipients: [
					{
						emailAddress: {
							address: environment.ReceivingEmail
						}
					}
				]
			},
			saveToSentItems: 'true'
		};
	}

	getMailContent(entry: EntryDB): string
	{
		let mailContent: string = '';
		if(entry['is_working_day']){
			if(entry['note'] != null && entry['note'] != undefined){
				mailContent += `${entry['date']} ${entry['place']} : ${entry['customers']} ${entry['time_from']} - ${entry['time_until']} ** ${entry['note']}</br>`;
			}else {
				mailContent += `${entry['date']} ${entry['place']} : ${entry['customers']} ${entry['time_from']} - ${entry['time_until']}</br>`;
			}
		}else{
			if(entry['note'] != null && entry['note'] != undefined){
				mailContent += `${entry['date']} ${entry['reason']} ** ${entry['note']}</br>`;
			}else {
				mailContent += `${entry['date']} ${entry['reason']}</br>`;
			}
		}
		return mailContent;
	}

	public sendEntryEmail(mail): void {
		try {
			this.logger.info('Method: sendEntryMail(), Status: successful');
			this.http.post(GraphUrl + '/me/sendMail', mail).subscribe();
		} catch (err) {
			this.globalErrorHandler.handleError(err);
			this.logger.trace(`Method: writeEntryDateToSheet,
				Mail: ${mail}, `
			);
		}
	}

	public sendTicketEmail(mail): Observable<any> {
		try {
			this.logger.info('Method: sendTicketMail(), Status: successful');
			return this.http.post(GraphUrl + '/me/sendMail', mail);
		} catch (err) {
			this.globalErrorHandler.handleError(err);
			this.logger.trace(`Method: writeEntryDateToSheet,
				Mail: ${mail}, `
			);
		}
	}

	public writeMail(receivingEmail, receivingEmail2, Subject, Description) {
		let Mail;
		if (receivingEmail2) {
			Mail = {
				message: {
					subject: Subject,
					body: {
						contentType: 'HTML',
						content: Description
					},

					toRecipients: [
						{
							emailAddress: {
								address: receivingEmail
							}
						},
						{
							emailAddress: {
								address: receivingEmail2
							}
						}
					]
				},
				saveToSentItems: 'true'
			};
		} else {
			Mail = {
				message: {
					subject: Subject,
					body: {
						contentType: 'HTML',
						content: Description
					},

					toRecipients: [
						{
							emailAddress: {
								address: receivingEmail
							}
						}
					]
				},
				saveToSentItems: 'true'
			};
		}

		return Mail;
	}
}
