import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule as MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule as MatButtonModule} from '@angular/material/button';
import {MatCardModule as MatCardModule} from '@angular/material/card';
import {MatCheckboxModule as MatCheckboxModule} from '@angular/material/checkbox';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule as MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule as MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule as MatRadioModule} from '@angular/material/radio';
import {MatInputModule as MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule as MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule as MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UrlSerializer} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faUserCircle} from '@fortawesome/free-regular-svg-icons';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatTabsModule as MatTabsModule} from '@angular/material/tabs';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSlideToggleModule as MatSlideToggleModule} from '@angular/material/slide-toggle';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {AppComponent} from './app.component';
import {AlertsComponent} from './_shared/components/alerts/alerts.component';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {NavBarComponent} from './nav-bar/nav-bar.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {TicketComponent} from './ticket/ticket.component';
import {DndDirective} from './directives/dnd/dnd.directive';
import {CustomUrlSerializer} from './_services/custom-url-serializer.service';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule as MatListModule} from '@angular/material/list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CheckboxComponent} from './_shared/components/checkbox/checkbox.component';
import {InputfieldComponent} from './_shared/components/inputfield/inputfield.component';
import {OverviewCardComponent} from './_shared/components/overview-card/overview-card.component';
import {BackendInterceptor} from './_shared/interceptor/backend.interceptor';
import {LoadingComponent} from './_shared/components/loading/loading.component';
import {BackendLoadingInterceptor} from './_shared/interceptor/backendloading';
import {MatTooltipModule as MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule as MatMenuModule} from '@angular/material/menu';

import {
	BrowserCacheLocation,
	InteractionType,
	IPublicClientApplication,
	LogLevel,
	PublicClientApplication
} from '@azure/msal-browser';
import {
	MSAL_GUARD_CONFIG,
	MSAL_INSTANCE,
	MSAL_INTERCEPTOR_CONFIG,
	MsalBroadcastService,
	MsalGuard,
	MsalGuardConfiguration,
	MsalInterceptor,
	MsalInterceptorConfiguration,
	MsalModule,
	MsalRedirectComponent,
	MsalService
} from '@azure/msal-angular';

import {SelectInputComponent} from './_shared/components/select-input/select-input.component';
import {MultiselectChipsComponent} from './_shared/components/multiselect-chips/multiselect-chips.component';
import {RemoveAsciiPipe} from './_shared/Pipes/removeAscii.pipe';
import {ChunkPipe} from './_shared/Pipes/calendar.pipe';
import {LogService} from './_services/loggingService/log.service';
import {GlobalErrorHandler} from './_services/errorHandler/globalErrorHandler';
import {appRegistrationIdV2, graphScope} from './_shared/constEnvironment';
import {registerLocaleData} from '@angular/common';
import {ShiftplanComponent} from './shiftplan/shiftplan.component';
import {MatProgressBarModule as MatProgressBarModule} from '@angular/material/progress-bar';

import localDe from '@angular/common/locales/de';
import {SnackbarService} from './_services/snackbarService/snackbar.service';
import {ShiftplanOfficeComponent} from './shiftplan-office/shiftplan-office.component';
import {MatChipsModule} from "@angular/material/chips";
import jwtDecode from "jwt-decode";
import {EntriesOverviewComponent} from './entries-overview/entries-overview.component';
import {EntryComponent} from './entries-overview/overview-window/entry/entry.component';
import {EditingWindowComponent} from './entries-overview/editing-window/editing-window.component';
import {OverviewWindowComponent} from './entries-overview/overview-window/overview-window.component';

registerLocaleData(localDe);

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback() {
	// console.debug(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
	return new PublicClientApplication({
		auth: {
			clientId: appRegistrationIdV2,
			authority: 'https://login.microsoftonline.com/5dc9cc33-bce8-4581-bc49-05e82d3943c2/', // saracus tenant id
			redirectUri: window.location.origin + '/home',
			postLogoutRedirectUri: window.location.origin,
			navigateToLoginRequestUrl: true
		},
		cache: {
			cacheLocation: BrowserCacheLocation.LocalStorage,
			storeAuthStateInCookie: isIE // set to true for IE 11
		},
		system: {
			loggerOptions: {
				loggerCallback,
				logLevel: LogLevel.Error,
				piiLoggingEnabled: false
			},
			iframeHashTimeout: 10000
		}
	});
}

async function acquireTokenSilently() {
	try {
		await MSALInstanceFactory().acquireTokenSilent({
			scopes: graphScope,
			account: MSALInstanceFactory().getAllAccounts()[0],
			forceRefresh: true
		});
	} catch (e) {
		await MSALInstanceFactory().logout()
	}
}

function getCurrentToken(): string {
	for (const key in localStorage) {
		if (key.includes('login.windows.net-idtoken')) {
			return JSON.parse(localStorage.getItem(key))['secret']
		}
	}
	MSALInstanceFactory().logout().then()
}

export async function getValidToken(): Promise<string> {
	let token = getCurrentToken()
	const decodedToken = jwtDecode(token)
	const expiresAt = decodedToken['exp']
	const now: Date = new Date()
	// eslint-disable-next-line no-magic-numbers
	const expiresAtMinus5Minutes = new Date(expiresAt * 1000 - 5 * 60 * 1000)
	if (now > expiresAtMinus5Minutes) {
		await acquireTokenSilently()
	}
	token = getCurrentToken()
	return token
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
	const protectedResourceMap = new Map<string, Array<string>>();
	protectedResourceMap.set('https://graph.microsoft.com/v1.0/', graphScope);

	return {
		interactionType: InteractionType.Redirect,
		protectedResourceMap
	};
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
	return {
		interactionType: InteractionType.Redirect,
		authRequest: {
			scopes: graphScope
		}
	};
}

library.add(faExternalLinkAlt);
library.add(faUserCircle);

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		NavBarComponent,
		AlertsComponent,
		PageNotFoundComponent,
		DndDirective,
		TicketComponent,
		LoginComponent,
		OverviewCardComponent,
		CheckboxComponent,
		InputfieldComponent,
		LoadingComponent,
		SelectInputComponent,
		MultiselectChipsComponent,
		RemoveAsciiPipe,
		ChunkPipe,
		ShiftplanComponent,
		ShiftplanOfficeComponent,
		EntriesOverviewComponent,
		EntryComponent,
		EditingWindowComponent,
		OverviewWindowComponent
	],
	exports: [],
	imports: [
		MsalModule,
		BrowserModule,
		AppRoutingModule,
		ReactiveFormsModule,
		FormsModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatSnackBarModule,
		MatButtonModule,
		MatDividerModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatAutocompleteModule,
		MatNativeDateModule,
		MatFormFieldModule,
		MatSidenavModule,
		MatInputModule,
		MatSelectModule,
		FontAwesomeModule,
		MatDialogModule,
		MatIconModule,
		MatCardModule,
		MatBadgeModule,
		MatRadioModule,
		MatExpansionModule,
		MatTabsModule,
		MatToolbarModule,
		MatListModule,
		MatCheckboxModule,
		DragDropModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatChipsModule,
		MatButtonToggleModule,
		MatProgressBarModule,
		MatMenuModule
	],
	providers: [
		{provide: HTTP_INTERCEPTORS, useClass: BackendInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: BackendLoadingInterceptor, multi: true},
		{
			provide: UrlSerializer,
			useClass: CustomUrlSerializer
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		},
		{
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		},
		{
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		},
		{
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		},
		{provide: LOCALE_ID, useValue: 'de'},
		MsalService,
		MsalGuard,
		MsalBroadcastService,
		LogService,
		GlobalErrorHandler,
		SnackbarService,
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		{provide: MAT_DATE_LOCALE, useValue: 'en-GB'}

	],
	bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
}
