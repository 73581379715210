import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-overview-card',
	templateUrl: './overview-card.component.html',
	styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent {
	@Input() title?;
	@Input() icon?;
	@Input() routing?;
	@Input() description?;
	@Input() usercolumnFlag: boolean;
}
