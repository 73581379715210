import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {UserDB} from "../../_entities/userDB";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class UserService {
	constructor(
		private http: HttpClient
	) {
	}

	listUsers(): Observable<UserDB[]> {
		return this.http.get<UserDB[]>(`${environment.OkitApiGatewayURL}/user`)
	}

	getPrivilegedUsers(): Observable<string[]> {
		return this.http.get<string[]>(`${environment.OkitApiGatewayURL}/privilegedUser`)
	}
}
