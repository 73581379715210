import {Component, OnInit} from '@angular/core';
import {OauthService} from './_services/oauthService/oauth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	constructor(
		private authService: OauthService
	) {
	}

	ngOnInit() {
		this.authService.checkAndSetActiveAccount();
	}
}
