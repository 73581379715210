<div class="overview-window">
	<div class="overview-header">
		<div class="overview-header-content">
			<img class="overview-icon"
				 src="./assets/icon/overview-icon.png"
				 alt="overview icon">
			<h2 class="overview-title">
				Gesamtmitarbeiterübersicht
			</h2>
		</div>
		<div class="overview-separator"></div>
	</div>
	<!-- filter settings for overview -->
	<div class="overview-filter">
		<form [formGroup]="filterForm"
			  class="overview-filter-content">
			<div class="overview-date">
				<h3 class="overview-date-title">
					Datum
				</h3>
				<div class="overview-date-field">
					<input matInput formControlName="dateForm"
						   [matDatepicker]="releasedAtPicker"
						   (dateInput)="onFilterFormChange()">
					<!-- calender to pick a date -->
					<mat-datepicker-toggle class="datepicker"
										   matSuffix [for]="releasedAtPicker">
					</mat-datepicker-toggle>
					<mat-datepicker #releasedAtPicker
									[startAt]="currentDate"
					>
					</mat-datepicker>
				</div>
			</div>
			<div class="overview-workplace">
				<h3 class="overview-workplace-title">
					Arbeitsort
				</h3>
				<input class="overview-workplace-field"
					   matInput
					   formControlName="workplaceForm"
					   [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete"
								  autoActiveFirstOption
								  (optionSelected)="onFilterFormChange()">
					<mat-option *ngFor="let workplace of workplaces"
								[value]="workplace">
						{{workplace}}
					</mat-option>
				</mat-autocomplete>
			</div>
			<div class="overview-absent-employees">
				<input class="overview-absent-employees-checkbox"
					   type="checkbox" formControlName="isAbsentEmployeesForm"
					   [checked]="areAbsentEmployeesSelected"
					   (click)="onCheckBoxClick($event)"/>
				<h3 class="overview-absent-employees-title">
					Abwesende Mitarbeiter
				</h3>
			</div>
		</form>
	</div>
	<!-- list of entries -->
	<div class="overview-entries">
		<div class="overview-entries-content">
			<div class="overview-entries-header">
				<h3 class="overview-entries-name">
					Name
				</h3>
				<h3 *ngIf="!areAbsentEmployeesSelected"
					class="overview-entries-workplace">
					Arbeitsort
				</h3>
				<h3 *ngIf="!areAbsentEmployeesSelected"
					class="overview-entries-customer">
					Kunde
				</h3>
				<h3 *ngIf="areAbsentEmployeesSelected"
					class="overview-entries-reason">
					Abwesenheitsgrund
				</h3>
				<h3 *ngIf="!areAbsentEmployeesSelected"
					class="overview-entries-worktime">
					Arbeitszeiten
				</h3>
				<h3 class="overview-entries-notice">
					Notiz</h3>
			</div>
			<div class="overview-entries-entries">
				<!-- entry configuration -->
				<app-entry *ngFor="let entry of entries"
						   [entry]="entry"
						   [areAbsentEmployeesSelected]="areAbsentEmployeesSelected"
						   [isEntrySelected]="entry === selectedEntry"
						   (entrySelected)="openEditingWindow($event)">
				</app-entry>
			</div>
		</div>
	</div>
</div>
