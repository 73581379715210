import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from '../home/home.component';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';
import {TicketComponent} from '../ticket/ticket.component';
import {shiftplanoffice, home, shiftplan, ticket, entriesoverview} from '../_shared/constants';
import {MsalGuard} from '@azure/msal-angular';
import {ShiftplanComponent} from '../shiftplan/shiftplan.component';
import {ShiftplanOfficeComponent} from "../shiftplan-office/shiftplan-office.component";
import {AuthOfficeGuard} from "./auth-office.guard";
import {EntriesOverviewComponent} from "../entries-overview/entries-overview.component";

export const routes: Routes = [
	{path: home, component: HomeComponent, canActivate: [MsalGuard]},
	{path: shiftplan, component: ShiftplanComponent, canActivate: [MsalGuard]},
	{path: ticket, component: TicketComponent, canActivate: [MsalGuard]},
	{path: shiftplanoffice, component: ShiftplanOfficeComponent, canActivate: [MsalGuard, AuthOfficeGuard]},
	{path: entriesoverview, component: EntriesOverviewComponent, canActivate: [MsalGuard, AuthOfficeGuard]},

	{path: '404', component: PageNotFoundComponent, canActivate: [MsalGuard]},
	{path: '**', redirectTo: '/404', canActivate: [MsalGuard]}
];

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes, {useHash: false})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
