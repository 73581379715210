import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {OauthService} from '../_services/oauthService/oauth.service';
import {shiftplanoffice, home,  mobileSize, shiftplan, ticket, entriesoverview} from '../_shared/constants';

@Component({
	selector: 'app-nav-bar',
	templateUrl: './nav-bar.component.html',
	 styleUrls: ['./nav-bar.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class NavBarComponent implements OnInit {
	showNav: boolean;
	public isSmallLayout = false;
	home = home;
	ticket = ticket;
	shiftplanoffice = shiftplanoffice;
	shiftplan = shiftplan;
	entriesoverview = entriesoverview;
	isOfficeTeam: boolean;
	isDropDownMenuSelected: boolean;

	constructor(public authService: OauthService) {
		this.authService.isOfficeTeam$.subscribe(value => {
			this.isOfficeTeam = value;
		});
		this.isDropDownMenuSelected = false;
	}

	ngOnInit() {
		this.showNav = false;
		window.onresize = () => this.isSmallLayout = window.innerWidth <= mobileSize;
	}

	signOut(): void {
		this.authService.signOut();
	}

	showDropDownMenu(){
		this.isDropDownMenuSelected = true;
	}

	hideDropDownMenu(){
		this.isDropDownMenuSelected = false;
	}
}
