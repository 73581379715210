<div class="pnf-container">
      <mat-card class="page-not-found-card"
        ><mat-card-header><br /></mat-card-header
        ><mat-card-content>
          <br />
          <h2>
            Error <strong class="highlight">404</strong>: Page Not Found
          </h2></mat-card-content
        >
        <br />
        <mat-card-actions>
          <a mat-flat-button color="accent" href="{{home}}" target="_self">
            Home</a
          ></mat-card-actions
        ><mat-card-footer></mat-card-footer>
      </mat-card>
</div>
