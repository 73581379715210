import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler} from '@angular/common/http';
import {Observable, from} from 'rxjs';
import {getValidToken} from "../../app.module";
import {mergeMap} from "rxjs/operators";

@Injectable()

export class BackendInterceptor implements HttpInterceptor {
	intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isValidRequestForInterceptor(httpRequest.url)) {
			return from(getValidToken()).pipe(
				mergeMap((token) => {
					const authorizedRequest = httpRequest.clone({
						setHeaders: { Authorization: 'Bearer ' + token }
					});
					return next.handle(authorizedRequest);
				})
			);
		} else {
			return next.handle(httpRequest);
		}
	}

	public isValidRequestForInterceptor(requestUrl: string): boolean {
		const positionIndicator = 'execute-api.eu-central-1.amazonaws.com';
		const position = requestUrl.indexOf(positionIndicator);
		return position > 0;
	}
}
