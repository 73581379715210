import {Component} from '@angular/core';
import {home} from "../_shared/constants";

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
	protected readonly home = home;
}
