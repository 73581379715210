<div class="body-container">
	<app-alerts></app-alerts>
	<div class="flex-wrap-space-between-container">
		<div class="login-item hover">
			<mat-card class="small-card">
				<div *ngIf="user" class="content">
					<mat-card-header class="overview-card-title">

						<img [alt]="user.givenName"
							 [src]="user.avatar"
							 class="profileMain" mat-card-avatar/>
						<mat-card-title class="profile-card-header">
							{{ user.givenName }}
							{{ user.surname }}
						</mat-card-title>
						<mat-card-subtitle class="profile-card-subtitle">
							{{ user.jobTitle }}
						</mat-card-subtitle>
					</mat-card-header>
				</div>
			</mat-card>
			<ng-template #showLoading>
				<img class="showLoading" alt="Bild nicht verfügbar!"
					 src="../../assets/svg-loaders/tail-spin.svg"/>
			</ng-template>
		</div>
		<div class="small-item hover">
			<mat-card  class="small-card" routerLink="{{shiftplan}}">
				<mat-card-content>
					<div>
						Einsatzplanstatus:
					</div>
					<div>
						<div *ngIf="!sheetPlanStatus then showLoading" class="flexbox-vertical-central">
						</div>
						<div *ngIf="sheetPlanStatus===1" class="flexbox-vertical-central"
							 matTooltip="Einträge für diese und nächste Woche sind vorhanden! 😊">
							<mat-icon class="mat-icon-green">circle</mat-icon>
						</div>
						<div *ngIf="sheetPlanStatus===2" class="flexbox-vertical-central"
							 matTooltip="Einträge für dise oder nächste Woche fehlen! 😐">
							<mat-icon class="mat-icon-yellow">circle</mat-icon>
						</div>
						<div *ngIf="sheetPlanStatus===3" class="flexbox-vertical-central"
							 matTooltip="Keine Einträge für diese und nächste Woche vorhanden!!! 🙁">
							<mat-icon class="mat-icon-red">circle</mat-icon>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>

		<!--Mail-->
		<div class="small-item mailcard hover">
			<mat-card (click)="openWebMail()" class="small-card">
				<mat-card-content>
					<mat-icon [matBadge]="counter" class="mail-icon" matBadgeColor="warn">email</mat-icon>
				</mat-card-content>
			</mat-card>
		</div>

		<div class="overview-item hover">
			<app-overview-card
							   [description]="'Plane und sehe deine Arbeitszeiten für die nächsten Wochen'"
							   [icon]="'date_range'"
							   [routing]="shiftplan"
							   [title]="'Einsatzplan '">
			</app-overview-card>

		</div>

		<div class="overview-item hover">
			<app-overview-card [description]="'Bei Problemen & fehlenden Berechtigungen kannst du ein Ticket erstellen'"
							   [icon]="'announcement'" [routing]="ticket"
							   [title]="'Ticket Service'"></app-overview-card>
		</div>
	</div>
</div>







