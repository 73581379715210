export const GraphUrl = 'https://graph.microsoft.com/v1.0';
export const graphScope = [
	'user.read',
	'files.readwrite.all',
	'mail.send',
	'mail.read',
	'mail.readbasic',
	'mail.readwrite'
];
export const appRegistrationIdV2 = '1d783caa-42b3-42ea-8856-95682378136e';

export const officeTeamId = "7b2c2b3e-13e8-4788-aab9-d4e385c168a2";
