import {Pipe, PipeTransform} from '@angular/core';
import {CalendarDay} from '../../_entities/CalenderDay';

@Pipe({
	name: 'chunk'
})
export class ChunkPipe implements PipeTransform {

	transform(calendarDaysArray: CalendarDay[], chunkSize: number): CalendarDay[][] {
		// Pipe to Split the 42 Calendar days into Weeks (7 Days)
		const calendarDays: CalendarDay[][] = [];
		let weekDays: CalendarDay [] = [];
		calendarDaysArray.map((day, index) => {
			weekDays.push(day);

			if (++index % chunkSize === 0) {
				calendarDays.push(weekDays);
				weekDays = [];
			}
		});
		return calendarDays;
	}
}
