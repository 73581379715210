import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {EntryDB} from "../../_entities/entryDB";
import {environment} from "../../../environments/environment";

@Injectable({
	providedIn: 'root'
})

/**
 * Angular service for entries overview to get, update, and delete entries from AWS DynamoDB database.
 */
export class EntriesOverviewService {

	constructor(private http: HttpClient) {
	}

	/**
	 * Converts the given date to the "yyyy-mm-dd" format.
	 * @param date The date to be converted.
	 * @returns A string representing the date in "yyyy-mm-dd" format.
	 */
	private dateToYYYYMMDD(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	/**
	 * Retrieves entries with users depending on selected date, workplace, and absent entries checkbox from AWS DynamoDB database.
	 * @param date The selected date.
	 * @param workplace The selected workplace.
	 * @param areAbsentEmployeesSelected A boolean indicating whether absent employees are selected.
	 * @returns An observable of EntryDB array.
	 */
	getEntries(date: Date = null, workplace: string = null, areAbsentEmployeesSelected: boolean = null): Observable<EntryDB[]> {
		let params: HttpParams = new HttpParams();
		params = params.set('date', this.dateToYYYYMMDD(date));
		params = params.set('workplace', workplace);
		params = params.set('areAbsentEmployeesSelected', areAbsentEmployeesSelected);
		const url: string = `${environment.OkitApiGatewayURL}/entriesOverview`
		return this.http.get<EntryDB[]>(url, {params})
	}

	/**
	 * Deletes the selected entry from AWS DynamoDB database.
	 * @param userId The user ID of the entry to be deleted.
	 * @param date The date of the entry to be deleted.
	 * @returns An observable of string.
	 */
	deleteEntry(userId: string = null, date: Date = null): Observable<null> {
		let params: HttpParams = new HttpParams();
		params = params.set('user_id', userId);
		params = params.set('date', this.dateToYYYYMMDD(date));
		const url: string = `${environment.OkitApiGatewayURL}/entriesOverview`
		return this.http.delete<null>(url, {params})
	}

	/**
	 * Updates the selected entry in AWS DynamoDB database.
	 * @param data The entry data to be updated.
	 * @returns An observable of string.
	 */
	updateEntry(data: EntryDB): Observable<null> {
		const url: string = `${environment.OkitApiGatewayURL}/entriesOverview`
		return this.http.patch<null>(url, data)
	}
}
