import {Component} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {OutlookService} from 'src/app/_services/outlook/outlook.service';

import {GraphService} from 'src/app/_services/graphService/graph.service';
import {environment} from 'src/environments/environment';
import {AlertsService} from 'src/app/_services/alertservice/alerts.service';
import {LogService} from '../_services/loggingService/log.service';
import {GlobalErrorHandler} from '../_services/errorHandler/globalErrorHandler';
import {
	problems
} from '../_shared/constants';

@Component({
	selector: 'app-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss']
})
export class TicketComponent {

	ticketForm: FormGroup;
	select: string;
	desc: string;
	mail: string;
	mail2: string;
	subject: string;
	problems: string[] = problems

	constructor(
		private fb: FormBuilder,
		private outlookService: OutlookService,
		private graphService: GraphService,
		private alertService: AlertsService,
		private logger: LogService,
		private globalErrorHandler: GlobalErrorHandler
	) {
		this.ticketForm = this.fb.group({
			input: '',
			selection: '',
			description: ''
		});
	}

	sendTicket(): void {
		if (this.select && this.desc) {
			switch (this.select) {
			case 'Bugs & Probleme Okit':
				this.mail = environment.OKITMail;
				this.subject = '[Ticket] Bugs & Probleme mit dem Okit';
				break;
			case 'Andere':
				this.mail = environment.AzureMail2;
				this.subject = '[Ticket] Anderes Anliegen';
				break;
			}

			const mail = this.outlookService.writeMail(
				this.mail,
				this.mail2,
				this.subject,
				this.desc
			);

			try {
				this.outlookService.sendTicketEmail(mail).subscribe(() => {
					this.logger.info('Method: sendTicket(),' + 'Absender: ' + this.outlookService.user.mail);
					this.alertService.add(
						'Danke, dein Ticket wurde aufgenommen und wird bearbeitet',
						'',
						'success'
					);
				});
			} catch (error) {
				this.alertService.add(
					'Da hat etwas nicht geklappt',
					JSON.stringify(error),
					'danger'
				);
				this.logger.trace('Method: sendTicket()' + ', ' +
					'Absender: ' + this.outlookService.user.mail + ', ' +
					'select: ' + this.select + ', ' +
					'desc: ' + this.desc + ', ' +
					'mail: ' + this.mail + ', ' +
					'mail2: ' + this.mail2 + ', ' +
					'subject: ' + this.subject
				);
				this.globalErrorHandler.handleError(error);
			}

			this.reset();
		}
	}

	reset(): void {
		this.ticketForm.reset();
		this.mail = null;
		this.mail2 = undefined;
	}
}
