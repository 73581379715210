export const shiftplan = 'einsatzplan';
export const entriesoverview = 'eintraegeuebersicht';
export const home = '';
export const ticket = 'ticket';
export const shiftplanoffice = 'einsatzplanoffice';
export const problems: string[] = ['Bugs & Probleme Okit', 'Andere'];
export const placeToWork: string[] = ['HomeOffice', 'Office', 'OnSite', 'Berufsschule'];
export const workplaces: string[] = ['alle', 'Berufsschule', 'HomeOffice', 'Office', 'OnSite'];
export const reasons: string[] = ['Kein Arbeitstag','Urlaub', 'Feiertag', 'Krank'];

export const DaysInAWeek = 7;
export const WorkdayInAWeek = 5;
export const SATURDAY_NUM = 6;
export const invalidTokenError = 401;
export const mobileSize = 991;
export const ProjectCapitalize = ['etl', 'crm', 'erp'];
export const ProjectDWH = 'dwh';
export const ProjectFrontend = 'frontend';
export const FrontEndStringLength = 8;
export const monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
	'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'
];
export const minutes = ['00', '15', '30', '45'];
export const hours = ['6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'];
export const loadingDefault = 100;
export const loadingDiameter = 70;
export const loadingStrokeWidth = 10;
