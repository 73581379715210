import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ShiftPlanEntryObject} from '../_entities/shiftPlanEntryObject';
import {
	DaysInAWeek,
	hours,
	minutes,
	monthNames,
	placeToWork,
	reasons,
	WorkdayInAWeek
} from '../_shared/constants';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {OutlookService} from '../_services/outlook/outlook.service';
import {CalendarDay} from '../_entities/CalenderDay';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {forkJoin, Observable} from 'rxjs';
import {MatChipInputEvent as MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {SheetPlanService} from '../_services/sheetPlanService/sheet-plan.service';
import {EntryDB} from "../_entities/entryDB";
import * as moment from "moment";
import {CustomerService} from "../_services/customerService/customer.service";

@Component({
	selector: 'app-shiftplan',
	templateUrl: './shiftplan.component.html',
	styleUrls: ['./shiftplan.component.scss']
})
export class ShiftplanComponent implements OnInit, OnChanges {


	@ViewChild('customerInput') customerInput: ElementRef<HTMLInputElement>;

	@Input() userId: string = null;

	dbEntries: EntryDB[];
	allCustomers: string[]
	customerControl = new UntypedFormControl();
	filteredCustomers: Observable<string[]>;
	filteredPlacesToWork: Observable<string[]>;
	filteredReasons: Observable<string[]>;
	includeWeekEnd: boolean;
	isWorkingDay: boolean = true;
	placeToWork = placeToWork;
	private monthIndex = new Date().getMonth();
	public DateForDateCard: Date;
	public calendarEntries: CalendarDay [] = [];
	public dateCardEditModeFlag: boolean;
	public entryForDateCard: ShiftPlanEntryObject;
	public entryDBSelected: EntryDB
	public hours: string[] = hours;
	public minutes: string[] = minutes;
	public monthNames: string[] = monthNames;
	public showDateCardFlag: boolean;
	public showRangeCard: boolean;
	public titleForRangeCard: string;
	rangeEnd: number;
	rangeSelection: boolean;
	rangeStart: number;
	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	reason = reasons;
	entryDateForm = new UntypedFormGroup({
		isWorkingDayForm: new UntypedFormControl(''),
		reasonForm: new UntypedFormControl(''),
		customerForm: new UntypedFormControl([], Validators.required),
		placeForm: new UntypedFormControl('', Validators.required),
		startTimeHoursForm: new UntypedFormControl(''),
		startTimeMinutesForm: new UntypedFormControl(''),
		endTimeHoursForm: new UntypedFormControl(''),
		endTimeMinutesForm: new UntypedFormControl(''),
		noteForm: new UntypedFormControl('')
	});


	constructor(
		private outlookService: OutlookService,
		private sheetPlanService: SheetPlanService,
		private customerService: CustomerService
	) {
	}

	ngOnChanges(): void {
		this.ngOnInit()
	}

	ngOnInit(): void {
		this.resetRange()
		this.generateCalendarDays(this.monthIndex)
		this.filteredCustomers = this.customerControl.valueChanges.pipe(
			startWith(''),
			map((customer: string | null) => customer ? this._customerFilter(customer) : this.allCustomers.slice()));

		this.filteredPlacesToWork = this.entryDateForm.get('placeForm').valueChanges.pipe(
			startWith(''),
			map(() => this.placeToWork)
		);

		this.filteredReasons = this.entryDateForm.get('reasonForm').valueChanges.pipe(
			startWith(''),
			map((value: string | null) => value ? this._reasonFilter(value) : this.reason)
		);
		this.customerService.getEntries().subscribe(data => {
			this.allCustomers = data
		});
		this.entryDateForm.valueChanges.subscribe(data => {
			// condition for short entry
			this.isWorkingDay = data.isWorkingDayForm;
			// validators for formControl
			if (!this.isWorkingDay) {
				this.customerForm.clearValidators();
				this.placeForm.clearValidators();
				this.reasonForm.setValidators(Validators.required);
			} else {
				this.customerForm.setValidators(Validators.required);
				this.placeForm.setValidators(Validators.required);
				this.reasonForm.clearValidators();

			}
			this.customerForm.updateValueAndValidity({emitEvent: false});
			this.placeForm.updateValueAndValidity({emitEvent: false});
			this.reasonForm.updateValueAndValidity({emitEvent: false});
		});
	}

	get customerForm(): AbstractControl {
		return this.entryDateForm.get('customerForm');
	}

	get placeForm(): AbstractControl {
		return this.entryDateForm.get('placeForm');
	}

	get reasonForm(): AbstractControl {
		return this.entryDateForm.get('reasonForm');
	}

	private getStartDateForCalendar(selectedDate: Date): Date {
		let startingDateOfCalendar: Date = new Date(selectedDate.setDate(0));

		if (startingDateOfCalendar.getDay() !== 0) {
			do {
				startingDateOfCalendar = new Date(startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() - 1));
			} while (startingDateOfCalendar.getDay() !== 0);
		}
		startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() + 1)
		return startingDateOfCalendar;
	}

	remove(customer: string): void {
		const index = this.customerForm.value.indexOf(customer);
		// Remove element from control value array
		this.customerForm.value.splice(index, 1);
		this.customerForm.updateValueAndValidity();
	}

	add(event: MatChipInputEvent): void {
		const input = event.chipInput;
		const value = event.value;

		// Add our Customers
		if ((value || '').trim()) {
			if (this.customerForm.value) {
				this.customerForm.setValue([...this.customerForm.value, value]);
			} else {
				this.customerForm.setValue([value]);
			}
			this.customerForm.updateValueAndValidity();
		}

		if (input) {
			input.placeholder = '';
		}
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		if (this.customerForm.value) {
			this.customerForm.setValue([...this.customerForm.value, event.option.viewValue]);
		} else {
			this.customerForm.setValue([event.option.viewValue]);
		}
		this.customerInput.nativeElement.value = '';
		this.customerControl.setValue(null);
	}


	setDefaultForMenu(): void {
		this.entryDateForm.patchValue({
			isWorkingDayForm: true,
			reasonForm: '',
			customerForm: '',
			placeForm: '',
			startTimeHoursForm: '9',
			startTimeMinutesForm: '00',
			endTimeHoursForm: '17',
			endTimeMinutesForm: '00',
			noteForm: ''
		});
	}

	createEntry(date: string): EntryDB {
		const timefrom: string = `${this.entryDateForm.value['startTimeHoursForm']}:${this.entryDateForm.value['startTimeMinutesForm']}`;
		const timeuntil: string = `${this.entryDateForm.value['endTimeHoursForm']}:${this.entryDateForm.value['endTimeMinutesForm']}`;
		return {
			date: date == undefined ? null : date,
			is_working_day: this.isWorkingDay,
			customers: String(this.entryDateForm.value['customerForm']) == undefined ? null : String(this.entryDateForm.value['customerForm']),
			place: this.entryDateForm.value['placeForm'] == undefined ? null : this.entryDateForm.value['placeForm'],
			time_until: timeuntil,
			time_from: timefrom,
			reason: this.entryDateForm.value['reasonForm'] == undefined ? null : this.entryDateForm.value['reasonForm'],
			note: this.entryDateForm.value['noteForm'] == undefined ? null : this.entryDateForm.value['noteForm']
		};
	}

	createEntryToNext(date: string, entryFromLastWeek: EntryDB): EntryDB {
		return {
			date: date,
			is_working_day: entryFromLastWeek.reason == undefined,
			customers: entryFromLastWeek.customers == undefined ? null : entryFromLastWeek.customers,
			place: entryFromLastWeek.place == undefined ? null : entryFromLastWeek.place,
			time_until: entryFromLastWeek.time_until == undefined ? null : entryFromLastWeek.time_until,
			time_from: entryFromLastWeek.time_from == undefined ? null : entryFromLastWeek.time_from,
			reason: entryFromLastWeek.reason == undefined ? null : entryFromLastWeek.reason,
			note: entryFromLastWeek.note == undefined ? null : entryFromLastWeek.note
		};
	}

	// Date Card
	openOneDateMenu(calenderDay: CalendarDay): void {
		this.showRangeCard = false;
		this.dateCardEditModeFlag = false;
		this.entryDateForm.reset();
		this.DateForDateCard = calenderDay.date;
		this.entryDBSelected = this.dbEntries.find(entry => entry.date === this.dateToYYYYMMDD(calenderDay.date));

		this.setDefaultForMenu();
		if (typeof this.entryDBSelected !== 'undefined') {
			if (this.entryDBSelected['is_working_day']) {
				this.createDataCardForWorkingDay()
			} else {
				this.createDataCardForNotWorkingDay()
			}
		} else {
			this.createEmptyDataCard()
		}
		this.showDateCardFlag = true;
	}

	createDataCardForWorkingDay(): void {
		this.entryForDateCard = {
			isWorkingDay: true,
			reason: '',
			place: this.entryDBSelected['place'],
			customer: this.entryDBSelected['customers'].split(','),
			startDate: this.entryDBSelected['time_from'],
			endDate: this.entryDBSelected['time_until'],
			note: this.entryDBSelected['note']
		};
		this.entryDateForm.patchValue({
			isWorkingDayForm: this.entryForDateCard.isWorkingDay,
			customerForm: this.entryForDateCard.customer,
			placeForm: this.entryForDateCard.place,
			startTimeHoursForm: this.transformFromTime(this.entryForDateCard.startDate)[0],
			startTimeMinutesForm: this.transformFromTime(this.entryForDateCard.startDate)[1],
			endTimeHoursForm: this.transformFromTime(this.entryForDateCard.endDate)[0],
			endTimeMinutesForm: this.transformFromTime(this.entryForDateCard.endDate)[1],
			noteForm: this.entryForDateCard.note == null ? null : this.entryForDateCard.note
		});
	}

	createDataCardForNotWorkingDay(): void {
		this.entryForDateCard = {
			isWorkingDay: false, reason: this.entryDBSelected['reason'], place: '', customer: [],
			startDate: '', endDate: '', note: this.entryDBSelected['note']
		};
		this.entryDateForm.patchValue({
			isWorkingDayForm: this.entryForDateCard.isWorkingDay,
			reasonForm: this.entryForDateCard.reason,
			noteForm: this.entryForDateCard.note == null ? null : this.entryForDateCard.note
		});
	}

	createEmptyDataCard(): void {
		this.dateCardEditModeFlag = true;
		this.entryForDateCard = {
			isWorkingDay: true, reason: '', place: '', customer: [],
			startDate: '', endDate: '', note: ''
		};
	}

	closeOneDateCard(): void {
		this.showDateCardFlag = false;
		this.dateCardEditModeFlag = false;
	}

	exitEditMode(): void {
		this.entryDBSelected = undefined
		this.dateCardEditModeFlag = false
	}

	saveEntryToDB(): void {
		const entry: EntryDB = this.createEntry(formatDate(this.DateForDateCard, "yyyy-MM-dd", 'en-US'));
		this.sheetPlanService.saveEntry(entry, this.userId).subscribe(() => {
			this.ngOnInit()
		})
	}

	updateEntry(): void {
		const entry: EntryDB = this.createEntry(formatDate(this.DateForDateCard, "yyyy-MM-dd", 'en-US'));
		this.sheetPlanService.updateEntry(entry, this.userId).subscribe(() => {
			this.ngOnInit()
		})
	}

	deleteEntry(): void {
		const date: string = formatDate(this.DateForDateCard, "yyyy-MM-dd", 'en-US');
		this.sheetPlanService.deleteEntry(date, this.userId).subscribe(() => {
			this.ngOnInit()
		})
	}

	addThisEntriesToNext(): void {
		const dbEntries = this.dbEntries
		this.dbEntries = [];
		const today: Date = new Date();
		const mondayOfCurrentWeek: Date = this.getMondayOfDate(today);
		const mondayOfNextWeek: Date = new Date(moment(mondayOfCurrentWeek).add(DaysInAWeek, 'day').toDate());
		const entries: EntryDB[] = [];
		let daysToCopy: number = !this.includeWeekEnd ? WorkdayInAWeek : DaysInAWeek;

		const iterationEntryDate: Date = new Date(mondayOfCurrentWeek);
		const iterationDateDate: Date = new Date(mondayOfNextWeek);
		const requests = [];

		while (daysToCopy > 0) {
			const entryFromLastWeekString = this.dateToYYYYMMDD(iterationEntryDate);
			const entryFromLastWeek: EntryDB = dbEntries.find(entry => entry.date === entryFromLastWeekString);

			if (entryFromLastWeek) {
				const entry: EntryDB = this.createEntryToNext(formatDate(new Date(iterationDateDate), 'yyyy-MM-dd', 'en'), entryFromLastWeek);
				requests.push(this.sheetPlanService.saveEntry(entry, this.userId));
				entries.push({...entry}); // Use object spread for cloning
			}

			daysToCopy--;
			iterationDateDate.setDate(iterationDateDate.getDate() + 1);
			iterationEntryDate.setDate(iterationEntryDate.getDate() + 1);
		}


		forkJoin(requests).subscribe(() => {
			this.sendEntryEmail(entries);
			this.ngOnInit();
		});
	}

	getMondayOfDate(date): Date {
		if (date.getDay() !== 1) {
			date.setDate(date.getDate() - 1);
			this.getMondayOfDate(date);
		}
		return date;
	}

	// Range Card
	openRangeMenu(startDateIndex, EndDateIndex): void {
		const firstDate = `${this.calendarEntries[startDateIndex].date.getDate()}. ${this.monthNames[this.calendarEntries[startDateIndex].date.getMonth()]}`;
		const secondDate = `${this.calendarEntries[EndDateIndex].date.getDate()}. ${this.monthNames[this.calendarEntries[EndDateIndex].date.getMonth()]}`;
		this.titleForRangeCard = `${firstDate} - ${secondDate}`;
		this.showRangeCard = true;
		this.showDateCardFlag = false;
		this.setDefaultForMenu();
	}

	closeRangeCard(): void {
		this.showRangeCard = false;
	}

	saveEntriesToDBRangeMenu(): void {
		let index: number = this.rangeStart;
		const entries: EntryDB[] = [];

		const requests = []
		while (index <= this.rangeEnd) {
			const entry: EntryDB = this.createEntry(formatDate(new Date(this.calendarEntries[index].date), 'yyyy-MM-dd', 'en'))
			requests.push(this.sheetPlanService.saveEntry(entry, this.userId));
			entries.push(JSON.parse(JSON.stringify(entry)));
			index++;
		}

		forkJoin(requests).subscribe(() => {
			this.sendEntryEmail(entries);
			this.ngOnInit()
		});
	}

	sendEntryEmail(entryData): void { // todo outsource this to backend
		const mail = this.outlookService.writeEntryMail(entryData);
		this.outlookService.sendEntryEmail(mail);
	}

	// range Selection
	toggleSelection(date, dayIndex, weekIndex): void {
		if (this.rangeSelection) {
			this.closeOneDateCard();
			this.endRangeSelection(dayIndex, weekIndex);
		} else {
			this.beginRangeSelection(dayIndex, weekIndex);
		}
	}

	beginRangeSelection(dayIndex, weekIndex): void {
		if (typeof this.rangeStart === 'number' && this.rangeEnd) {
			this.removeDateRange(this.rangeStart, this.rangeEnd);
		}
		const index = dayIndex + DaysInAWeek * weekIndex;
		this.rangeSelection = true;
		this.rangeStart = index;
		this.calendarEntries[index].isSelectedDate = true;
		this.updateSelection(dayIndex, weekIndex);
	}

	endRangeSelection(dayIndex, weekIndex): void {
		this.updateSelection(dayIndex, weekIndex);
		this.rangeSelection = false;
		if (this.rangeEnd < this.rangeStart) {
			const tempEndDate = this.rangeEnd;
			this.rangeEnd = this.rangeStart;
			this.rangeStart = tempEndDate;
		}
	}

	updateSelection(dayIndex, weekIndex): void {
		const index = dayIndex + DaysInAWeek * weekIndex;
		if (this.rangeSelection) {
			if (this.rangeEnd !== index && this.rangeEnd) {
				this.cleanCalender();
				this.calendarEntries[this.rangeStart].isSelectedDate = true;
			}
			this.rangeEnd = index;
			this.calendarEntries[index].isSelectedDate = true;
		}
		if (this.rangeEnd < this.rangeStart) {
			let counter = this.rangeEnd;
			while (counter < this.rangeStart) {
				if (!this.includeWeekEnd && this.calendarEntries[counter].weekEnd === true) {
					counter++;
				} else {
					this.calendarEntries[counter].isRangeDate = true;
					counter++;
				}
			}
		} else {
			let counter = this.rangeStart;
			while (counter < this.rangeEnd) {
				if (!this.includeWeekEnd && this.calendarEntries[counter].weekEnd === true) {
					counter++;
				} else {
					this.calendarEntries[counter].isRangeDate = true;
					counter++;
				}
			}
		}
	}

	removeDateRange(start, end): void {
		this.calendarEntries[start].isSelectedDate = false;
		this.calendarEntries[end].isSelectedDate = false;
		let counter = this.rangeStart;
		while (counter < this.rangeEnd) {
			this.calendarEntries[counter].isRangeDate = false;
			counter++;
		}
	}

	cleanCalender(): void {
		for (const elem of this.calendarEntries) {
			elem.isSelectedDate = false;
			elem.isRangeDate = false;
		}
	}

	resetRange(): void {
		this.includeWeekEnd = false;
		this.calendarEntries = [];
		this.dateCardEditModeFlag = false;
		this.showRangeCard = false;
		this.showDateCardFlag = false;
		this.entryDateForm.reset();
		this.rangeStart = null;
		this.rangeEnd = null;
		this.rangeSelection = false;
		this.cleanCalender();
	}

	getMonth(): string {
		const day: Date = new Date(new Date().setMonth(this.monthIndex, 1));
		return `${this.monthNames[day.getMonth()]} ${day.getFullYear()}`;
	}

	displayEntry(entry: string): string {
		return entry.split('_').join(' ');
	}


	// calender

	public increaseMonth(): void {
		this.monthIndex++;
		this.generateCalendarDays(this.monthIndex);
		this.resetRange();
	}

	public decreaseMonth(): void {
		this.monthIndex--;
		this.generateCalendarDays(this.monthIndex);
		this.resetRange();

	}

	public setCurrentMonth(): void {
		this.monthIndex = 0;
		this.generateCalendarDays(this.monthIndex);
		this.resetRange();

	}

	entryForDateChange(): void {
		this.dateCardEditModeFlag = true;
	}

	transformFromTime(timeString): string {
		if (timeString) {
			return timeString.split(':');
		}
	}

	// filter for customer and workplace
	private _customerFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.allCustomers.filter(newValue => newValue.toLowerCase().includes(filterValue));
	}

	private _reasonFilter(value: string): string[] {
		const filterValue = value.toLowerCase();
		return this.reason.filter(newValue => newValue.toLowerCase().includes(filterValue));
	}

	private generateCalendarDays(monthIndex: number): void {
		const DaysDisplayed = 42;
		this.calendarEntries = [];
		const day: Date = new Date(new Date().setMonth(monthIndex, 1));
		const startDate = this.getStartDateForCalendar(day);
		const endRange = new Date(startDate)
		endRange.setDate(endRange.getDate() + DaysDisplayed)

		this.sheetPlanService.getEntries(this.userId, startDate, endRange).subscribe(entries => {
			this.dbEntries = entries
			for (let i = 0; i < DaysDisplayed; i++) {
				const dateToAdd = new Date(startDate)
				dateToAdd.setDate(dateToAdd.getDate() + i)

				const dateToAddString = this.dateToYYYYMMDD(dateToAdd)
				const entry = this.dbEntries.find(entry => entry.date === dateToAddString);
				const entryString = this.convertEntryToString(entry)
				const calendarItem = new CalendarDay(
					dateToAdd,
					entryString,
					false
				)
				this.calendarEntries.push(calendarItem);
			}
		})
	}


	private dateToYYYYMMDD(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	private convertEntryToString(entry: EntryDB): string {
		let entryString: string = ''
		if (typeof entry !== 'undefined') {
			if (entry['is_working_day']) {
				if (entry['note'] != null || undefined) {
					entryString = `${entry['place']} : ${entry['customers']} ${entry['time_from']} - ${entry['time_until']} ** ${entry['note']}`
				} else {
					entryString = `${entry['place']} : ${entry['customers']} ${entry['time_from']} - ${entry['time_until']}`
				}
			} else {
				if (entry['note'] != null || undefined) {
					entryString = `${entry['reason']} ** ${entry['note']}`
				} else {
					entryString = `${entry['reason']}`
				}
			}
		}
		return entryString
	}
}
