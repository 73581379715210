import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {environment} from '../../../environments/environment';
import {EntryDB} from "../../_entities/entryDB";

@Injectable({
	providedIn: 'root'
})

export class SheetPlanService {

	constructor(
		private http: HttpClient
	) {
		// this.createEntryMap()
	}

	private dateToYYYYMMDD(date: Date): string {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	}

	getEntries(userId: string = null, startDate: Date = null, endDate: Date = null): Observable<EntryDB[]> { //todo
		let params = new HttpParams();
		if (startDate) {
			params = params.set('startDate', this.dateToYYYYMMDD(startDate));
		}
		if (endDate) {
			params = params.set('endDate', this.dateToYYYYMMDD(endDate));
		}
		let url: string
		if (!userId) {
			url = `${environment.OkitApiGatewayURL}/sheetPlan`
		} else {
			url = `${environment.OkitApiGatewayURL}/sheetPlan/office/${userId}`
		}
		return this.http.get<EntryDB[]>(url, {params})
	}

	saveEntry(data: EntryDB, userId: string = null): Observable<EntryDB> {//todo
		let url: string
		if (!userId) {
			url = `${environment.OkitApiGatewayURL}/sheetPlan`
		} else {
			url = `${environment.OkitApiGatewayURL}/sheetPlan/office/${userId}`
		}
		return this.http.post<EntryDB>(url, data)
	}

	updateEntry(data: EntryDB, userId: string = null): Observable<EntryDB> {//todo
		let url: string
		if (!userId) {
			url = `${environment.OkitApiGatewayURL}/sheetPlan`
		} else {
			url = `${environment.OkitApiGatewayURL}/sheetPlan/office/${userId}`
		}
		return this.http.post<EntryDB>(url, data)
	}

	deleteEntry(date: string, userId: string = null): Observable<string> {//todo
		let url: string
		const options = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json'
			}),
			body: {
				date: date,
				user_id: null
			}
		};
		if (!userId) {
			url = `${environment.OkitApiGatewayURL}/sheetPlan`
		} else {
			options.body.user_id = userId
			url = `${environment.OkitApiGatewayURL}/sheetPlan/office/${userId}`
		}
		return this.http.delete<string>(url, options)
	}



}
