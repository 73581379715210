<div class="editing-window">
	<div class="editing-window-header">
		<img class="editing-window-icon"
			 src="./assets/icon/editing-window-icon.png"
			 alt="editing window icon">
		<h2 class="editing-window-title">
			Eintragsübersicht
		</h2>
		<img class="editing-window-close-icon"
			 src="./assets/icon/editing-window-close-icon.png"
			 alt="close editing window icon"
			 (click)="closeEditingWindow.emit()">
	</div>
	<!-- overview of selected entry. Which fields will be showed depends on entry type (the employee is present or absent for the selected day)-->
	<div class="editing-window-entry-overview" *ngIf="entry !== editedEntry">
		<p class="entry-field">
			<span class="bold">
				Name:
			</span>
			{{entry.user_name}}
		</p>
		<p *ngIf="isWorkingDay"
		   class="entry-field">
			<span class="bold">
				Arbeitsort:
			</span>
			{{entry.place}}
		</p>
		<p *ngIf="isWorkingDay"
		   class="entry-field">
			<span class="bold">
				Kunde:
			</span>
			{{entry.customers}}
		</p>
		<p *ngIf="!isWorkingDay"
		   class="entry-field">
			<span class="bold">
				Grund:
			</span>
			{{entry.reason}}
		</p>
		<p *ngIf="isWorkingDay"
		   class="entry-field">
			<span class="bold">
				Arbeitszeiten:
			</span>
			{{entry.time_from}} - {{entry.time_until}}
		</p>
		<p class="entry-field">
			<span class="bold">
				Notiz:
			</span>
			{{entry.note}}
		</p>
	</div>
	<!-- edit mode for selected entry. Which fields will be showed depends on is working day checkbox checked or not-->
	<div class="editing-window-entry-edit-mode"
		 *ngIf="entry === editedEntry">
		<form [formGroup]="entryForm" class="editing-window-entry-entry-form">
			<div class="entry-form-field entry-form-field-is-working-day">
				<input class="is-working-day-checkbox"
					   type="checkbox"
					   formControlName="isWorkingDayForm"
					   [checked]="this.isWorkingDay"
					   (click)="onCheckBoxClick($event)"/>
				<p class="entry-form-title
				is-working-day-checkbox-title">
					Arbeitstag
				</p>
			</div>
			<div class="entry-form-field"
				 *ngIf="!isWorkingDay">
				<p class="entry-form-title">
					Grund
				</p>
				<input class="field-input reason-input"
					   matInput
					   formControlName="reasonForm"
					   [matAutocomplete]="auto"
					   placeholder="Wähle einen Grund"
					   required>
				<mat-autocomplete #auto="matAutocomplete"
								  autoActiveFirstOption>
					<mat-option *ngFor="let reason of reasons "
								[value]="reason">
						{{ reason }}
					</mat-option>
				</mat-autocomplete>
			</div>
			<div class="entry-form-field"
				 *ngIf="isWorkingDay">
				<p class="entry-form-title">
					Kunde
				</p>
				<div class="field-input customer-input">
					<mat-select formControlName="customerForm"
								placeholder="Wähle einen Kunden"
								multiple
								required>
						<mat-option *ngFor="let customer of customers"
									[value]="customer">
							{{ customer }}
						</mat-option>
					</mat-select>
				</div>
			</div>
			<div class="entry-form-field"
				 *ngIf="isWorkingDay">
				<p class="entry-form-title">Ort</p>
				<input class="field-input workplace-input"
					   matInput
					   formControlName="workplaceForm"
					   [matAutocomplete]="auto"
					   placeholder="Wähle einen Ort"
					   required>
				<mat-autocomplete #auto="matAutocomplete"
								  autoActiveFirstOption>
					<mat-option *ngFor="let workplace of workplaces "
								[value]="workplace">
						{{ workplace }}
					</mat-option>
				</mat-autocomplete>
			</div>
			<div class="entry-form-field time-form-left"
				 *ngIf="isWorkingDay">
				<p class="entry-form-title">
					Von
				</p>
				<div class="time-form">
					<input class="field-input time-input"
						   matInput
						   formControlName="startTimeHoursForm"
						   [matAutocomplete]="auto"
						   required>
					<mat-autocomplete #auto="matAutocomplete"
									  autoActiveFirstOption>
						<mat-option *ngFor="let inputHours of hours"
									[value]="inputHours">
							{{ inputHours }}
						</mat-option>
					</mat-autocomplete>
					<p class="time-colon">
						:
					</p>
					<input class="field-input time-input"
						   matInput
						   formControlName="startTimeMinutesForm"
						   [matAutocomplete]="auto2"
						   required>
					<mat-autocomplete #auto2="matAutocomplete"
									  autoActiveFirstOption>
						<mat-option *ngFor="let inputMinutes of minutes"
									[value]="inputMinutes">
							{{ inputMinutes }}
						</mat-option>
					</mat-autocomplete>
				</div>
			</div>
			<div class="entry-form-field"
				 *ngIf="isWorkingDay">
				<p class="entry-form-title">
					Bis
				</p>
				<div class="time-form">
					<input class="field-input time-input"
						   matInput
						   formControlName="endTimeHoursForm"
						   [matAutocomplete]="auto"
						   required>
					<mat-autocomplete #auto="matAutocomplete"
									  autoActiveFirstOption>
						<mat-option *ngFor="let inputHours of hours"
									[value]="inputHours">
							{{ inputHours }}
						</mat-option>
					</mat-autocomplete>
					<p class="time-colon">
						:
					</p>
					<input class="field-input time-input"
						   matInput
						   formControlName="endTimeMinutesForm"
						   [matAutocomplete]="auto2"
						   required>
					<mat-autocomplete #auto2="matAutocomplete"
									  autoActiveFirstOption>
						<mat-option *ngFor="let inputMinutes of minutes"
									[value]="inputMinutes">
							{{ inputMinutes }}
						</mat-option>
					</mat-autocomplete>
				</div>
			</div>
			<div class="entry-form-field">
				<p class="entry-form-title">
					Notiz
				</p>
				<textarea class="field-input notice-input"
						  formControlName="noteForm"
						  type="text">
				</textarea>
			</div>
		</form>
	</div>
	<!-- editing window buttons -->
	<div class="editing-window-buttons"
		 *ngIf="entry !== editedEntry">
		<button class="editing-window-button"
				(click)="editEntry()">
			Bearbeiten
		</button>
		<button class="editing-window-button red-button"
				(click)="deleteEntry()">
			Löschen
		</button>
	</div>
	<div class="editing-window-buttons"
		 *ngIf="entry === editedEntry">
		<button class="editing-window-button"
				(click)="updateEntry()">
			Speichern
		</button>
		<button class="editing-window-button red-button"
				(click)="notEditEntry()">
			Verwerfen
		</button>
	</div>
</div>
