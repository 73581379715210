<div
	style="box-shadow: 0 0 3px rgb(0, 0, 0);"
	class="navbar">


	<mat-toolbar class="navbar-toolbar" *ngIf="!isSmallLayout">
		<div class="navbar-toolbar-content">
			<div class="navbar-left">
				<div class="home"><a routerLink="{{home}}" href="#">
					<img src="../../assets/img/Logo_Saracus_blauschw_FINAL.svg" class="img-fit" alt=""/>
				</a></div>
				<div class="shiftplan"><button mat-flat-button class="mat-flat-button" routerLink="{{shiftplan}}" routerLinkActive="active">
					Einsatzplan
				</button></div>
				<div class="request-ticket"><button mat-flat-button class="mat-flat-button" routerLink="{{ticket}}" routerLinkActive="active">
					Request Ticket
				</button></div>
				<div class="dropdown-menu"
					 (mouseenter)="showDropDownMenu()"
					 (mouseleave)="hideDropDownMenu()">
					<button *ngIf="isOfficeTeam"
							mat-flat-button
							class="mat-flat-button"  >
						Einsatzplan Office
					</button>
					<!-- dropdown list with links for office employee, it can be seen and selected only by office employees -->
					<div class="dropdown-menu-content"
						 *ngIf="isOfficeTeam && isDropDownMenuSelected">
						<div>
							<button  mat-flat-button
									 class="mat-flat-button"
									 routerLink="{{entriesoverview}}"
									 routerLinkActive="active">
								Gesamtmitarbeiterübersicht
							</button>
						</div>
						<div>
							<button mat-flat-button
									class="mat-flat-button"
									routerLink="{{shiftplanoffice}}"
									routerLinkActive="active">
								Einzelmitarbeiterübersicht
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="navbar-right">
				<button mat-flat-button class="mat-flat-button" (click)="signOut()">
					<span>Log Out</span>
					<span><mat-icon class="logout-icon" color="accent">logout</mat-icon></span>
				</button>
			</div>
		</div>
	</mat-toolbar>

	<mat-toolbar class="navbar-toolbar" *ngIf="isSmallLayout">
		<div class="navbar-toolbar-content">
			<a routerLink="{{home}}" href="#">
				<img src="../../assets/img/Logo_Saracus_blauschw_FINAL.svg" class="img-fit" alt=""/>
			</a>
			<div>
				<button mat-button [matMenuTriggerFor]="menu">
					<mat-icon class="black">menu</mat-icon>
				</button>
				<mat-menu class="burger-menu" #menu="matMenu">
					<button mat-flat-button class="mat-flat-button" routerLink="{{shiftplan}}"
							routerLinkActive="active">Einsatzplan
					</button>
					<button mat-flat-button class="mat-flat-button" routerLink="{{ticket}}" routerLinkActive="active">
						Request Ticket
					</button>
					<button *ngIf="isOfficeTeam" mat-flat-button class="mat-flat-button" routerLink="{{entriesoverview}}"
							routerLinkActive="active">
						Gesamtmitarbeiterübersicht
					</button>
					<button *ngIf="isOfficeTeam" mat-flat-button class="mat-flat-button" routerLink="{{shiftplanoffice}}"
							routerLinkActive="active">
						Einzelmitarbeiterübersicht
					</button>
					<button mat-flat-button class="mat-flat-button" (click)="signOut()">
						<span>Log Out</span>
						<span><mat-icon class="logout-icon" color="accent">logout</mat-icon></span>
					</button>
				</mat-menu>
			</div>
		</div>

	</mat-toolbar>


</div>


