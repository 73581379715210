<div class="shiftplan-container" [style.width]="userId ? '100%' : '80%'">
	<app-alerts></app-alerts>

	<div class="calendar-card">
		<mat-card>
			<mat-card-header>
				<div mat-card-avatar>
					<mat-icon class="material-icons md-30">date_range</mat-icon>
				</div>
				<mat-card-title>Einsatzplan</mat-card-title>
			</mat-card-header>
			<mat-divider></mat-divider>
			<mat-card-content>
				<div *ngIf="calendarEntries.length===0" class="flex-container-horizontal marginY1em">
					<mat-progress-bar mode="indeterminate"></mat-progress-bar>
				</div>
				<div>
					<div class="flex-container-horizontal marginY1em">
						<div>
							<button (click)="decreaseMonth()" class="marginX2px" color="warn" mat-stroked-button>
								<mat-icon class="material-icons arrow">arrow_back_ios_new</mat-icon>
							</button>
							<button (click)="setCurrentMonth()" color="warn" mat-stroked-button>
								<mat-icon class="material-icons arrow">today</mat-icon>
							</button>
							<button (click)="increaseMonth()" class="marginX2px" color="warn" mat-stroked-button>
								<mat-icon class="material-icons arrow">arrow_forward_ios_new</mat-icon>
							</button>

						</div>
						<div>
							<p class="monthYearText">{{getMonth()}}</p>
						</div>
						<div>
							<ng-container *ngIf="!userId">
								<button (click)="addThisEntriesToNext()" class="mat-button-line-height-fix marginX1em"
										color="warn"
										mat-stroked-button
										matTooltip="Die Einträge der aktuellen Woche werden auf die nächste Woche übertragen"
								>
									<mat-icon class="material-icons md-24">queue</mat-icon>
									<span>Einträge übernehmen</span>
								</button>
								<mat-slide-toggle [(ngModel)]="includeWeekEnd"
												  matTooltip="Wenn die Wochenende mit eingetragen werden sollen  ">
									Wochenende miteinbeziehen
								</mat-slide-toggle>
							</ng-container>
						</div>
					</div>
					<table *ngIf="calendarEntries.length>0" class='calendar-table'>
						<thead>
						<tr>
							<th>Montag</th>
							<th>Dienstag</th>
							<th>Mittwoch</th>
							<th>Donnerstag</th>
							<th>Freitag</th>
							<th>Samstag</th>
							<th>Sonntag</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let row of calendarEntries | chunk: 7; let i = index">
							<td (click)="openOneDateMenu(c)"
								(mousedown)="toggleSelection(c,j,i)"
								(mousemove)="updateSelection(j,i)"
								(mouseup)="endRangeSelection(j,i); openRangeMenu(rangeStart, rangeEnd)"
								*ngFor="let c of row; let j = index"
								[ngClass]="{'past-date': c.isPastDate, 'today': c.isToday, 'selectedDate' : c.isSelectedDate, 'rangeDate' : c.isRangeDate, 'weekend' : c.weekEnd }"
								class="calendar-day">
								<div [ngClass]="{'highlight': c.isToday}" class="calendar-day-header">
									<strong>{{c.date.getDate()}}</strong> <strong
									*ngIf="c.isToday || (i===0 && j===0) || (c.date.getDate() === 1)"> {{monthNames[c.date.getMonth()]}}</strong>
								</div>
								<br>
								<div>
									<p (dblclick)="entryForDateChange()"
									   class="calendar-day-entry">{{displayEntry(c.entry)}}</p>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<!--		Card for One Date -->
	<div *ngIf="showDateCardFlag" class="entry-card">
		<mat-card class="min-width">
			<mat-card-header class="flex-between">
				<div class="mat-card-avatar order1">
					<mat-icon class="material-icons md-30">event</mat-icon>
				</div>
				<div class="order2">
					<mat-card-title
					>{{DateForDateCard | date:'fullDate'}}
					</mat-card-title>
				</div>
				<div class="order3">
					<button (click)="closeOneDateCard()" class="closeMatIconButton" color="warn"
							mat-icon-button>
						<mat-icon>close</mat-icon>
					</button>
				</div>
			</mat-card-header>
			<mat-card-content>
				<div *ngIf="!dateCardEditModeFlag">
					<div *ngIf="entryForDateCard.place !== null">
						<div
							*ngIf="!entryForDateCard.isWorkingDay">
							<p><span class="strong-font">Grund: </span>{{entryForDateCard.reason}}</p>
							<p><span class="strong-font">Notiz: </span> <i>{{entryForDateCard.note}}</i></p></div>
						<div
							*ngIf="entryForDateCard.isWorkingDay">
							<p><span class="strong-font">Ort: </span>{{entryForDateCard.place}}</p>
							<p><span class="strong-font">Kunde: </span>{{entryForDateCard.customer}}</p>
							<p><span class="strong-font">Von: </span>{{entryForDateCard.startDate}}</p>
							<p><span class="strong-font">Bis: </span>{{entryForDateCard.endDate}}</p>
							<p><span class="strong-font">Notiz: </span> <i>{{entryForDateCard.note}}</i></p></div>
					</div>
				</div>

				<!--editmode -->
				<div *ngIf="dateCardEditModeFlag">
					<ng-template [ngTemplateOutlet]="entryForm"></ng-template>
				</div>

			</mat-card-content>
			<mat-card-actions align="end">
				<div>
					<div *ngIf="entryForDateCard.place !== null  && !dateCardEditModeFlag">
						<button (click)="entryForDateChange()"
								class="mat-button-line-height-fix marginRem"
								mat-raised-button
								title="Senden">
							Eintrag bearbeiten
						</button>
						<button (click)="deleteEntry()"
								class="mat-button-line-height-fix marginRem"
								color="warn"
								mat-raised-button
								title="Senden">
							Eintrag löschen
						</button>
					</div>
					<div *ngIf="dateCardEditModeFlag">
						<button (click)="saveEntryToDB()"
								[disabled]="!this.entryDateForm.valid" class="mat-button-line-height-fix marginRem"
								[hidden]="this.entryDBSelected !== null"
								color="warn"
								mat-flat-button>
							Speichern
						</button>
						<button
							(click)="updateEntry()"
							[disabled]="!this.entryDateForm.valid" class="mat-button-line-height-fix marginRem"
							[hidden]="this.entryDBSelected === null"
							color="warn"
							mat-flat-button>
							Speichern
						</button>
						<button (click)="exitEditMode()"
								[hidden]="this.entryDBSelected === null"
								class="mat-button-line-height-fix marginRem"
								mat-stroked-button>
							Änderungen verwerfen
						</button>
					</div>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>

	<!--		Card for Range of Dates -->
	<div *ngIf="showRangeCard" class="entry-card">
		<mat-card class="min-width">
			<mat-card-header class="flex-between">
				<div mat-card-avatar>
					<mat-icon>event</mat-icon>
				</div>
				<mat-card-title>
					{{titleForRangeCard}}
				</mat-card-title>
				<button (click)="closeRangeCard()" class="closeMatIconButton" color="warn"
						mat-icon-button>
					<mat-icon>close</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content>

				<ng-template [ngTemplateOutlet]="entryForm"></ng-template>
			</mat-card-content>
			<mat-card-actions align="end">
				<div>
					<button (click)="saveEntriesToDBRangeMenu()"
							[disabled]="!this.entryDateForm.valid" class="mat-button-line-height-fix " color="warn"
							mat-flat-button
							type="submit">
						Speichern
					</button>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>
</div>


<ng-template #entryForm>
	<form [formGroup]="entryDateForm">
		<div>
			<h6>
				<mat-checkbox class="example-margin no-padding-left"
							  formControlName="isWorkingDayForm"
							  [checked]=true>Arbeitstag
				</mat-checkbox>
			</h6>

		</div>
		<div *ngIf="!isWorkingDay">
			<h6>Grund</h6>
			<mat-form-field appearance="outline" floatLabel="always">
				<input [matAutocomplete]="auto"
					   formControlName="reasonForm"
					   matInput
					   placeholder="Wähle einen Grund"
					   required>
				<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
					<mat-option *ngFor="let reason of filteredReasons | async"
								[value]="reason">{{ reason}}</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</div>
		<div *ngIf="isWorkingDay">
			<h6>Kunde</h6>
			<mat-form-field appearance="outline" class="example-chip-list"
							floatLabel="always">
				<mat-chip-grid *ngIf="allCustomers" #chipList formControlName="customerForm">
					<mat-chip
						(removed)="remove(customer)"
						*ngFor="let customer of entryDateForm.get('customerForm').value">
						{{customer}}
						<mat-icon matChipRemove>cancel</mat-icon>
					</mat-chip>
					<input
						#customerInput
						(matChipInputTokenEnd)="add($event)"
						[formControl]="customerControl"
						[matAutocomplete]="auto"
						[matChipInputFor]="chipList"
						[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						placeholder="Weitere Kunden ... ">
				</mat-chip-grid>
				<button (click)="$event.stopPropagation()" mat-icon-button matSuffix>
					<mat-icon>add</mat-icon>
				</button>
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" [panelWidth]="300">
					<mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer">
						{{customer}}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
		</div>
		<div *ngIf="isWorkingDay">
			<div>
				<h6>Ort</h6>
				<mat-form-field appearance="outline">
					<input [matAutocomplete]="auto"
						   formControlName="placeForm"
						   matInput
						   placeholder="Wähle einen Ort"
						   required>
					<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
						<mat-option *ngFor="let workplace of filteredPlacesToWork | async"
									[value]="workplace">{{ workplace}}</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<div>
				<h6>Von</h6>
				<mat-form-field appearance="outline" class="time-form marginRem">
					<mat-label class="time-label">Stunden</mat-label>
					<mat-select formControlName="startTimeHoursForm" placeholder="Stunden">
						<mat-option *ngFor="let startHours of hours"
									[value]="startHours">{{ startHours }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="time-form" floatLabel="always">
					<mat-label class="time-label">Minuten</mat-label>
					<mat-select formControlName="startTimeMinutesForm">
						<mat-option *ngFor="let startMinutes of minutes"
									[value]="startMinutes">{{ startMinutes }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>


			<div class="inline-display">
				<h6>Bis</h6>
				<mat-form-field appearance="outline" class="time-form marginRem">
					<mat-label class="time-label">Stunden</mat-label>
					<mat-select formControlName="endTimeHoursForm">
						<mat-option *ngFor="let endHours of hours"
									[value]="endHours">{{ endHours }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline" class="time-form">
					<mat-label class="time-label">Minuten</mat-label>
					<mat-select formControlName="endTimeMinutesForm">
						<mat-option *ngFor="let endMinutes of minutes"
									[value]="endMinutes">{{ endMinutes }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<div>
			<h6>Notiz</h6>
			<mat-form-field appearance="outline">
				<mat-label></mat-label>
				<textarea formControlName="noteForm" matInput type="text"></textarea>
			</mat-form-field>
		</div>
	</form>
</ng-template>
