<div class="entries-overview">
	<!-- overview window -->
	<div [ngClass]="selectedEntry === null ? 'overview-window-container-100' : 'overview-window-container-75' ">
		<app-overview-window (openOrCloseEditingWindow)="openOrCloseEditingWindow($event)"
							 [overviewShouldBeUpdated]="overviewShouldBeUpdated">
		</app-overview-window>
	</div>
	<!-- editing entry window. It will be showed when an entry is selected -->
	<div class="editing-window-container-25"
		 *ngIf="selectedEntry !== null">
		<app-editing-window [entry]="selectedEntry"
							[isWorkingDay]="selectedEntry['is_working_day']"
							(closeEditingWindow)="updateOverview()">
		</app-editing-window>
	</div>
</div>


