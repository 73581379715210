import {SATURDAY_NUM} from '../_shared/constants';

export class CalendarDay {
	public date: Date;
	public title: string;
	public isPastDate: boolean;
	public isToday: boolean;
	public entry: string;
	public isSelectedDate: boolean;
	public isRangeDate: boolean;
	public weekEnd: boolean;

	constructor(d: Date, entry?, isSelected?) {
		this.date = d;
		this.isPastDate = d.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
		this.isToday = d.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
		this.entry = entry;
		this.isSelectedDate = isSelected;
		this.isRangeDate = false;
		this.weekEnd = d.getDay() === 0 || d.getDay() === SATURDAY_NUM;
	}
}
