import { Injectable } from '@angular/core';
import {OauthService} from "../_services/oauthService/oauth.service";

@Injectable({
	providedIn: 'root'
})
export class AuthOfficeGuard {

	isOfficeTeam: boolean;

	constructor(private authService: OauthService) {
		this.authService.isOfficeTeam$.subscribe(value => {
			this.isOfficeTeam = value;
		});
	}

	canActivate(): boolean{
		return this.isOfficeTeam;
	}

}
