import {DomSanitizer} from '@angular/platform-browser';
import {User} from '../../_entities/user';
import {invalidTokenError} from '../../_shared/constants';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {GlobalErrorHandler} from '../errorHandler/globalErrorHandler';
import {GraphUrl, officeTeamId} from '../../_shared/constEnvironment';


import {Inject, Injectable} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {RedirectRequest} from '@azure/msal-browser';
import {UserService} from "../userService/user.service";


@Injectable({
	providedIn: 'root'
})
export class OauthService {
	private user = new BehaviorSubject<User>({});
	public user$ = this.user.asObservable();

	private isOfficeTeam = new BehaviorSubject<boolean>(false);
	public isOfficeTeam$ = this.isOfficeTeam.asObservable();

	constructor(
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private authService: MsalService,
		private sanitizer: DomSanitizer,
		private http: HttpClient,
		private globalErrorHandler: GlobalErrorHandler,
		private userService: UserService
	) {
	}

	checkAndSetActiveAccount(): void {
		this.getUser();
		this.getGroups();
	}

	signOut(): void {
		this.user.next(null);
		this.isOfficeTeam.next(false);
		this.authService.logout()
	}

	loginWithRedirect(): void {
		if (this.msalGuardConfig.authRequest) {
			this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
		} else {
			this.authService.loginRedirect();
		}
	}

	private getUser(): void {
		this.http.get(GraphUrl + '/me').subscribe(value => {
			this.user.next(value);
			this.user.value.abbreviation = (this.user.value.surname.substring(0, 2) + this.user.value.givenName.substring(0, 2)).toLowerCase();
			this.http.get(GraphUrl + '/me/photos/240x240/$value', {responseType: 'blob'}).subscribe(blob => {
				const urlLocal = window.URL;
				this.user.value.avatar = this.sanitizer.bypassSecurityTrustUrl(urlLocal.createObjectURL(blob));
			}, () => {
				this.user.value.avatar = '../../../assets/img/default_avatar.png';
			});
		}, (err) => {
			if (err.statusCode === invalidTokenError) {
				this.signOut();
			}

			this.globalErrorHandler.handleError(err);
		});
	}

	private getGroups(): void {
		const tempGroups: Array<string> = new Array<string>()
		this.http.get<any>(GraphUrl + '/me/memberOf').subscribe(value => {
			for (const e of value.value) {
				tempGroups.push(e.id)
			}
			if (tempGroups.includes(officeTeamId)) {
				this.isOfficeTeam.next(true);
			} else {
				this.http.get<any>(GraphUrl + '/me').subscribe(user => {
					this.userService.getPrivilegedUsers().subscribe(
						developers => {
							if (developers.includes(user.userPrincipalName)) {
								this.isOfficeTeam.next(true);
							}
						})

				});
			}
		});
	}
}


