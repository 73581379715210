<mat-card tabindex="0"
		  class="overview-card"
		  [routerLink]="[routing]">
	<mat-card-header class="overview-card-title">
		<div mat-card-avatar>
			<mat-icon>{{icon}}</mat-icon>
		</div>
		<mat-card-title>{{title}}</mat-card-title>
	</mat-card-header>

	<mat-card-content class="overview-card-content">
		<p>{{description}}</p>
	</mat-card-content>
	<div *ngIf="usercolumnFlag">
	<mat-card-actions align="end" class="overview-card-actions">
		<a [routerLink]="[routing]"
		   role="button"
		   mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</a>
	</mat-card-actions>
	</div>
</mat-card>
