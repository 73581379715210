import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar as MatSnackBar} from '@angular/material/snack-bar';


@Injectable()
export class SnackbarService {
	constructor(
		private snackBar: MatSnackBar,
		private zone: NgZone
	) {

	}

	public open(message: string): void {
		this.zone.run(() => {
			this.snackBar.open(message);
		});
	}
}
