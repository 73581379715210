import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {UserDB} from "../_entities/userDB";
import {UserService} from "../_services/userService/user.service";

@Component({
	selector: 'app-shiftplan-office',
	templateUrl: './shiftplan-office.component.html',
	styleUrls: ['./shiftplan-office.component.scss']
})
export class ShiftplanOfficeComponent {


	@ViewChild('customerInput') customerInput: ElementRef<HTMLInputElement>;
	filteredUsers: UserDB[];
	public user: UserDB;
	public users: UserDB[];
	userControl: FormControl = new FormControl();

	constructor(
		private userService: UserService
	) {
		this.getUsers()
	}


	onUserSelect(event) {
		this.user = event.option.value;
		this.userControl.setValue(this.user.name)
	}

	getUsers() {
		this.userService.listUsers().subscribe(
			value => {
				this.users = value
				this.filteredUsers = this.users
			}
		)
	}


	filterUsers() {
		if(this.userControl.value != null){
			this.filteredUsers = this.users.filter(value => value.name.toLowerCase().includes(this.userControl.value))
		}else{
			this.filteredUsers = this.users
		}
	}
}
