import {Component, OnInit, OnChanges, Output, EventEmitter, Input, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {workplaces} from '../../_shared/constants';
import {map, startWith} from "rxjs/operators";
import {EntriesOverviewService} from '../../_services/eintriesOverviewService/entries-overview.service';
import {EntryDB} from "../../_entities/entryDB";

@Component({
	selector: 'app-overview-window',
	templateUrl: './overview-window.component.html',
	styleUrls: ['./overview-window.component.scss'],
	encapsulation: ViewEncapsulation.None
})
/**
 * This Angular component represents the entries overview window with filter settings.
 */
export class OverviewWindowComponent implements OnInit, OnChanges {
	@Input() overviewShouldBeUpdated: boolean;
	@Output() openOrCloseEditingWindow: EventEmitter<EntryDB> = new EventEmitter<EntryDB>();
	@Output() absentOrPresentEmployeesSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
	currentDate: Date;
	areAbsentEmployeesSelected: boolean;
	workplaces: string[] = workplaces;
	entries: EntryDB[];
	filterForm: FormGroup = new FormGroup({
		dateForm: new FormControl<Date>(null, Validators.required),
		workplaceForm: new FormControl<string>(null, Validators.required),
		isAbsentEmployeesForm: new FormControl<boolean>(null, Validators.required)
	});
	selectedEntry: EntryDB;
	constructor(private entriesOverviewService: EntriesOverviewService) {
		this.currentDate = new Date()
		this.areAbsentEmployeesSelected = false;
		// initialize filter settings
		this.filterForm.patchValue({
			dateForm: this.currentDate,
			workplaceForm: 'alle',
			isAbsentEmployeesForm: this.areAbsentEmployeesSelected
		})
		// get all defined workplaces for workplace filter setting
		this.filterForm.get('workplaceForm').valueChanges.pipe(
			startWith(''),
			map(() => this.workplaces)
		);
		this.selectedEntry = null;
	}

	/**
	 * Updates the overview when there are changes.
	 */
	ngOnChanges(): void {
		if (this.overviewShouldBeUpdated) {
			this.getEntries();
		}
		this.overviewShouldBeUpdated = false;
		this.selectedEntry = null;
	}

	/**
	 * Initializes the component and retrieves entries for the current date.
	 */
	ngOnInit(): void {
		this.getEntries();
	}

	/**
	 * Handles checkbox click event to update the overview based on whether absent or present employees are selected.
	 * @param e The click event.
	 */
	onCheckBoxClick(e) {
		this.areAbsentEmployeesSelected = e.target.checked;
		// check if checkbox activated or not. If checkbox activated then it is impossible to filter workplace.
		if (this.areAbsentEmployeesSelected) {
			this.filterForm.controls['workplaceForm'].disable()
		} else {
			this.filterForm.controls['workplaceForm'].enable()
		}
		this.onFilterFormChange();
		this.absentOrPresentEmployeesSelected.emit(this.areAbsentEmployeesSelected);
	}

	/**
	 * Updates the overview when filter settings change.
	 */
	onFilterFormChange() {
		this.getEntries();
		this.openOrCloseEditingWindow.emit(null)
		this.selectedEntry = null;
	}

	/**
	 * Retrieves entries for the selected date and workplace from the AWS DynamoDB database.
	 */
	getEntries() {
		const workplace = this.filterForm.controls['workplaceForm'].getRawValue();
		const date = this.filterForm.controls['dateForm'].getRawValue();
		this.entriesOverviewService.getEntries(date, workplace, this.areAbsentEmployeesSelected).subscribe(entries => {
			this.entries = entries;
		})
	}

	/**
	 * Opens the entry editing window if an entry is selected.
	 * @param entry The selected entry.
	 */
	openEditingWindow(entry: EntryDB) {
		this.openOrCloseEditingWindow.emit(entry)
		this.selectedEntry = entry;
	}
}
