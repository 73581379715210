import {Injectable} from '@angular/core';

import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import {environment} from '../../../environments/environment';

@Injectable()

export class LogService {

	constructor() {
		remote.apply(log, this.checkForProd());
	}

	private logConfig = {
		url: 'https://2iikbm39ge.execute-api.eu-central-1.amazonaws.com/dev/logger',
		method: 'POST',
		headers: {},
		token: '',
		timeout: 0,
		interval: 10,
		level: 'trace',
		backoff: {
			multiplier: 2,
			jitter: 0.1,
			limit: 30000
		},
		capacity: 500,
		stacktrace: {
			levels: ['trace', 'error'],
			depth: 10,
			excess: 0
		},
		timestamp: () => new Date().toISOString(),
		format: LogService.formatJson
	};

	private static formatJson(myLog) {
		myLog.level = myLog.level.label;
		delete myLog.logger;
		return myLog;
	}

	private checkForProd() {
		if (environment.production) {
			log.enableAll();
			return this.logConfig;
		}
		log.disableAll();
	}


	info(msg: string): void {
		log.info(msg);
	}

	warn(msg: string): void {
		log.warn(msg);
	}

	error(msg: string): void {
		log.error(msg);
	}

	trace(msg: string): void {
		log.trace(msg);
	}
}
