import {Injectable} from '@angular/core';
import {AlertsService} from '../alertservice/alerts.service';
import {OutlookService} from '../outlook/outlook.service';
import {HttpClient} from '@angular/common/http';
import {LogService} from '../loggingService/log.service';
import {GraphUrl} from '../../_shared/constEnvironment';
import {BehaviorSubject, Observable} from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class GraphService {
	// hardcode for dev
	private url: string = GraphUrl;
	private unreadMailCounter: BehaviorSubject<number> = new BehaviorSubject<number>(null);
	public unreadMailCounter$: Observable<number> = this.unreadMailCounter.asObservable();

	constructor(
		private alertService: AlertsService,
		private outlookService: OutlookService,
		private http: HttpClient,
		private logger: LogService
	) {}
	// to get the Count of unread Messages in the INBOX of the Users Mail
	countUnreadMessages(): void {
		try {
			this.http
				.get(
					this.url + '/me/mailFolders/Inbox/messages?$filter=isRead eq false&$select=isRead&$count=true'
				).subscribe(res => this.unreadMailCounter.next(res['@odata.count']));
		} catch (error) {
			this.alertService.add(
				'Ungelesene Emails konnten nicht geladen werden',
				JSON.stringify(error),
				'danger'
			);
			this.logger.warn('Method: countUnreadMessages, Status: Ungelesene Emails konnten nicht geladen werden');
		}
	}
}


