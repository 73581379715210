import {Component} from '@angular/core';
import {EntryDB} from "../_entities/entryDB";

@Component({
	selector: 'app-entries-overview',
	templateUrl: './entries-overview.component.html',
	styleUrls: ['./entries-overview.component.scss']
})
/**
 * Main Angular component for entries overview which consists of an overview window and an editing window.
 */
export class EntriesOverviewComponent {
	selectedEntry: EntryDB;
	overviewShouldBeUpdated: boolean;
	constructor() {
		this.selectedEntry = null;
		this.overviewShouldBeUpdated = false;
	}

	/**
	 * Opens or closes the editing window based on the selected entry.
	 * @param entry The entry to be edited. Pass null to close the editing window.
	 */
	openOrCloseEditingWindow(entry: EntryDB) {
		this.selectedEntry = entry;
		this.overviewShouldBeUpdated = this.selectedEntry == null;
	}

	/**
	 * Updates the overview when an entry is edited or the editing window is closed.
	 */
	updateOverview() {
		this.overviewShouldBeUpdated = true;
		this.selectedEntry = null;
	}
}
