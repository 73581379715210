import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpEvent, HttpRequest, HttpHandler} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LoadingService} from '../../_services/loading/loading.service';

@Injectable()

export class BackendLoadingInterceptor implements HttpInterceptor {
	constructor(private loadService: LoadingService) {
	}

	intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.isValidRequestForInterceptor(httpRequest.url)) {
			this.loadService.setLoading(true, httpRequest.url);
			return next.handle(httpRequest).pipe(
				finalize(() => this.loadService.setLoading(false, httpRequest.url)));
		} else {
			return next.handle(httpRequest);
		}
	}

	public isValidRequestForInterceptor(requestUrl: string): boolean {
		// is only valid for call to our own api, not for any azure calls
		const positionIndicator = 'execute-api';
		const position = requestUrl.indexOf(positionIndicator);
		return position > 0;
	}
}
