import {Component, OnInit} from '@angular/core';
import {OauthService} from '../_services/oauthService/oauth.service';
import {GraphService} from '../_services/graphService/graph.service';
import {forkJoin, interval, Observable, Subscription} from 'rxjs';
import {SheetPlanService} from '../_services/sheetPlanService/sheet-plan.service';
import {User} from '../_entities/user';
import {Router} from '@angular/router';
import {home, shiftplan, ticket} from '../_shared/constants';
import {LogService} from '../_services/loggingService/log.service';

const Interval = 5000;

const oneWeek: number = 7
const smallWeek: number = 6

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	user: User = null;
	counter: number;
	home = '/' + home;
	ticket = '/' + ticket;
	sheetPlanStatus: number;


	private MailIntervalObservable: Observable<number>;
	private MailIntervalSubscription: Subscription;


	constructor(
		private authService: OauthService,
		private graphService: GraphService,
		private router: Router,
		private logger: LogService,
		private sheetPlanService: SheetPlanService
	) {
		this.counter = null;
		this.authService.user$.subscribe(user => {
			this.user = user
		});
		this.router.events.subscribe(() => {
			document.title = 'Office Kit';
			if (this.MailIntervalSubscription != null) {
				this.MailIntervalSubscription.unsubscribe();

			}
		});
		this.graphService.unreadMailCounter$.subscribe(count => this.counter = count);

	}


	ngOnInit() {
		this.getUnreadMessages();
		if (this.MailIntervalSubscription == null) {
			this.MailIntervalObservable = interval(Interval);
			this.MailIntervalSubscription = this.MailIntervalObservable.subscribe(() => {
				this.getUnreadMessages();
				this.changeTitle();
			});
		}
		this.getSheetPlanStatus()
	}

	getWeeKStartAndEndDate(date: Date): [Date, Date] {
		const dayIndex = (date.getDay() + smallWeek) % oneWeek; // Ensure Sunday is 6, Monday is 0, Tuesday is 1, etc.

		const startDate = new Date(date);
		startDate.setDate(date.getDate() - dayIndex);

		const endDate = new Date(startDate);
		endDate.setDate(startDate.getDate() + smallWeek);

		return [startDate, endDate];
	}


	getSheetPlanStatus() {
		let entriesThisWeek: boolean;
		let entriesNextWeek: boolean;

		const today = new Date();
		const todayNextWeek = new Date(today);
		todayNextWeek.setDate(today.getDate() + oneWeek);

		const [startOfThisWeek, endOfThisWeek] = this.getWeeKStartAndEndDate(today);
		const [startOfNextWeek, endOfNextWeek] = this.getWeeKStartAndEndDate(todayNextWeek);

		const entriesThisWeek$ = this.sheetPlanService.getEntries(null, startOfThisWeek, endOfThisWeek);
		const entriesNextWeek$ = this.sheetPlanService.getEntries(null, startOfNextWeek, endOfNextWeek);

		forkJoin([entriesThisWeek$, entriesNextWeek$]).subscribe(([entriesThisWeekResponse, entriesNextWeekResponse]) => {
			entriesThisWeek = entriesThisWeekResponse.length > 0;
			entriesNextWeek = entriesNextWeekResponse.length > 0;

			if (entriesNextWeek && endOfNextWeek) {
				this.sheetPlanStatus = 1;
			} else if (!entriesThisWeek && entriesNextWeek || entriesThisWeek && !entriesNextWeek) {
				this.sheetPlanStatus = 2;
			} else {
				this.sheetPlanStatus = 3;
			}
		});
	}

	getUnreadMessages(): void {
		this.graphService.countUnreadMessages();
	}

	openWebMail(): void {
		try {
			window.open('https://outlook.office.com/mail/inbox');
			this.logger.info('Method: openWebMail()' + ' Kürzel: ' + this.user.abbreviation);
		} catch (err) {
			this.logger.warn('Method: openWebMail(), Status: Outlook konnte nicht gestartet werden');
		}

	}

	changeTitle(): void {
		if (this.counter !== 0) {
			document.title = '(' + this.counter + ') ' + 'OfficeKit';
		} else {
			document.title = 'OfficeKit';
		}
	}

	protected readonly shiftplan = shiftplan;
}
